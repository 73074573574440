import { Injectable } from "@angular/core";
import { MenuItem } from "primeng/api";
export const  permissionsMenu: { link?: string; label: string; permissions: string[] }[] = [
    {
        permissions: ["Octopus"],
        label: "Octopus Yönetim"
    },
    {
        link: "/octopus/assistancefirms",
        permissions: ["Octopus"],
        label: "Firma Yönetim"
    },
    {
        link: "/operator/",
        permissions: ["Dashboard"],
        label: "Ana Sayfa",
    },
    {
        link: "/settings/versionSettings",
        permissions: ["VersionSettings"],
        label: "Versiyonlar",
    },
    {
        link: "/operator/payment-control",
        permissions: ["Finance.Payment.List"],
        label: "Ödeme Mutabakat",
    },
    {
        link: "/operator/bill-list",
        permissions: ["Finance.Bill.List"],
        label: "Fatura Takip",
    },
    {
        permissions: ["Finance"],
        label: "Muhasebe",
    },
    // {
    //     link: "/operator/request",
    //     permissions: ["Request"],
    //     label: "Çağrı Karşılama",
    // },
    {
        link: "/operator/file-list",
        permissions: ["File-List"],
        label: "Dosya Listesi",
    },
    {
        link: "/operator/service-list",
        permissions: ["Service-List"],
        label: "Hizmet Listesi",
    },
    {
        link: "/operator/operations",
        permissions: ["Operations"],
        label: "Operasyon Kontrol",
    },
    {
        link: "/operator/complaint",
        permissions: ["Complaint"],
        label: "Şikayet Kaydı",
    },
    {
        link: "/operator/activateService",
        permissions: ["ActivateService"],
        label: "Servis Aktifleştirme",
    },
    {
        link: "/operator/announcements",
        permissions: ["Show.Announcements"],
        label: "Duyurular",
    },
    {
        link: "/operator/autoTask",
        permissions: ["AutoTask.View"],
        label: "Otomatik Görev Düzenleme",
    },
    {
        link: "/operator/octo-map",
        permissions: ["Show-MapMenuBar"],
        label: "Tedarikçi Haritası",
    },
    {
        link: "/operator/service-locations-map",
        permissions: ["Show.ServiceLocationsMap"],
        label: "Hizmet Lokasyon Haritası",
    },
    {
        link: "/operator/scripts",
        permissions: ["Show.Scripts"],
        label: "Scriptler",
    },
    {
        permissions: ["Process"],
        label: "İşlemler",
    },
    {
        link: "/settings/Customers",
        permissions: ["Customers"],
        label: "Müşteriler",
    },
    {
        link: "/settings/counters-table",
        permissions: ["Counters-List"],
        label: "Counters Tanımları",
    },
    {
        permissions: ["GlobalDefinition"],
        label: "Genel Tanımlar",
    },
    {
        link: "/settings/supplier-quota-list",
        permissions: ["Supplier-Quota-List"],
        label: "Tedarikçi Kota Listesi",
    },
    {
        permissions: ["VehicleDefinition"],
        label: "Araç Tanımları",
    },
    {
        permissions: ["OccurrenceTypes"],
        label: "Dosya Tanımları",
    },
    {
        link: "/settings/table/locationtypes",
        permissions: ["Show.ServiceLocations"],
        label: "Lokasyon Tipleri",
    },
    {
        link: "/settings/octoservicelocation",
        permissions: ["Show.ServiceLocations"],
        label: "Hizmet Lokasyonları",
    },
    {
        link: "/settings/table/slacategories",
        permissions: ["Show.SLADefinition"],
        label: "SLA Kategori Tanımları",
    },
    {
        permissions: ["Services"],
        label: "Hizmet Tanımları",
    },
    {
        permissions: ["Show.DashbordAnomalyReport"],
        label: "Dashboard Anomali Raporları",
        link: "/operator/dashboard-anomaly",
    },
    {
        permissions: ["Prices"],
        label: "Fiyat/Ödeme Tanımları",
    },
    {
        permissions: ["ComplaintReasons"],
        label: "Şikayet Tanımları",
    },
    {
        link: "/operator/userlist",
        permissions: ["UserList"],
        label: "Kullanıcılar",
    },
    {
        link: "/settings/permission",
        permissions: ["Permission"],
        label: "Yetki Tanımları",
    },
    {
        permissions: ["UserRoles"],
        label: "Kullanıcılar ve Roller",
    },
    {
        permissions: ["Statuses"],
        label: "Durum Tanımları",
    },
    {
        permissions: ["Definition"],
        label: "Tanımlar",
    },
    {
        link: "/operator/reporter",
        permissions: ["Reporter"],
        label: "Finans Raporu",
    },
    {
        link: "/operator/customerreports",
        permissions: ["CustomerReports"],
        label: "Müşteri Raporu",
    },
    {
        link: "/operator/supplierpricereport",
        permissions: ["SupplierPriceReport"],
        label: "Tedarikçi Fiyat Raporu",
    },
    {
        link: "/operator/supplierreport",
        permissions: ["SupplierReport"],
        label: "Tedarikçi Raporu",
    },
    {
        link: "/operator/supplierServiceDashboard",
        permissions: ["Show.Supplier-Service-Dashboard"],
        label: "Tedarikçi Hizmet Gösterge Paneli",
    },
    {
        link: "/operator/surveyreport",
        permissions: ["SurveyReport"],
        label: "Memnuniyet Anket Raporu",
    },
    {
        link: "/operator/servicesReport",
        permissions: ["ServicesReport"],
        label: "Hizmet Raporu",
    },
    {
        link: "/operator/service-statu-change-report",
        permissions: ["ServicesReport"],
        label: "Hizmet Durum Değişim Raporu",
    },
    {
        link: "/operator/ikm-service-day-extentaion",
        permissions: ["ServicesReport"],
        label: "İkame Hizmeti Gün Uzama Raporu",
    },
    {
        link: "/operator/towing-service-report",
        permissions: ["ServicesReport"],
        label: "Çekici Hizmet Raporu",
    },
    {
        link: "/operator/markReport",
        permissions: ["ServicesReport"],
        label: "İz Raporu",
    },
    {
        link: "/operator/googleLogs",
        permissions: ["GoogleLogs"],
        label: "Google Logs",
    },
    {
        link: "/operator/userGuides",
        permissions: ["Show.CreateUserGuides"],
        label: "Kullanıcı Kılavuzları",
    },
    {
        link: "/operator/slaCategoriesDashboard",
        permissions: ["Show.SlaDashboard"],
        label: "SLA Kategori Gösterge Paneli",
    },
    {
        label: 'Kampanya Tanımları', 
        link: "/campaigns/campaign-creat", 
        permissions: ["campaign-create"],
    },
    {
        permissions: ["Report"],
        label: "Raporlar",
    },
];
@Injectable({
    providedIn: "root"
})
export class PermissionsService {
    constructor() { }


    setMenuVisibility(menu: MenuItem[]) {
        for (let itemIndex = 0; itemIndex < menu.length; itemIndex++) {
            const menuItem = menu[itemIndex];

            if (menuItem.visible === false) {
                //  Başka bir kontrolden görünmemesi sağlanması durumu
                continue;
            }

            if (menuItem.items && menuItem.items.length > 0) {
                //  Gurup olması durumu
                menuItem.items = this.setMenuVisibility(menuItem.items);
            }

            const permissionItem = permissionsMenu.find(permission => permission.label === menuItem.label);
            if (!permissionItem) {
                // Seperatörler gibi labeli olmayan itemler durumu
                continue;
            }

            menuItem.visible = this.permissionCheck(permissionItem.permissions);
        }

        return menu;
    }



    private permissionCheck(permissions: string[]) {
        const savedPermissions = localStorage.getItem('user_roles');
        const item = JSON.parse(savedPermissions);

        for (let index = 0; index < permissions.length && item; index++) {
            const permission = permissions[index];
            const hasPermission = item.find((i: any) => i?.permissionName?.toLocaleUpperCase("tr") === permission?.toLocaleUpperCase("tr"))?.isList;

            if (!hasPermission) {
                return false;
            }
        }
        return true;
    }
}
