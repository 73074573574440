import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DefaultLayoutComponent } from '../default-layout/default-layout.component';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AppSettingsService } from '../../services/settings/app-settings.service';
import { TaskSocketService } from './task-socket/task.socket';
import { AnnouncementSocketService } from './announcement-socket/announcement.socket';
import { AnnouncementService } from '../../pages/operator/announcement/services/announcement.service';
import { ScriptSearchComponent } from 'src/app/pages/operator/script/components/script-search/script-search.component';
import { SelfNotesComponent } from '../../components/self-notes/self-notes.component';
import { SubscriberServiceService } from '../../services/operator/subscriber-service.service';
import { ISubscriberServiceListItem } from '../../domain/subscriber-service';
import { OperationControlComponent } from '../../pages/supplier/supplier-operation/operation-control/operation-control.component';
import { OccurenceParameterService } from '../../services/occurence-parameter.service';
import { OctoSupplierNotificationsService } from '../../octo-supplier-notifications/octo-supplier-notifications.service';
import { AppMessageService } from '../../services/common/app-message.service';
import { Subscription , timer } from 'rxjs';
import { AsistanceFirmService } from '../../octopus-manager/asistance-firm/services/asistance-firm.service';


@Component({
  selector: 'app-topbar',
  templateUrl: './app-topbar.component.html',
  styleUrls: ['./app-topbar.component.css']
})
export class AppTopbarComponent implements OnInit {
  @ViewChild('scriptSearch') scriptSearchComponent: ScriptSearchComponent;
  @ViewChild('notes') notes: SelfNotesComponent;
  @ViewChild('operationControlComponent') operationControlComponent: OperationControlComponent;

  firmLogo: string = '';

  boop: HTMLAudioElement;
  userName: string;
  surname: string;
  email: string;
  showOnay = false;
  userId: string;
  private sc: TaskSocketService
  taskValue = 0;
  private asc: AnnouncementSocketService
  announcementValue = 0;
  role: string;
  supplierId: string;
  supplierServiceInfo: ISubscriberServiceListItem[] = [];
  intervalId: NodeJS.Timeout;
  noteCount: number = 0;
  notificationMinutes: number = 1;
  socketService: OctoSupplierNotificationsService;
  testCount: string = '0';

  optionChangesInformation: any[] = [];
  showOptionChanges: boolean = false;
  private optionInfoSubscription: Subscription;

  constructor(
    public app: DefaultLayoutComponent,
    public appSettings: AppSettingsService,
    public router: Router,
    public authService: AuthService,
    private announcementService: AnnouncementService,
    private subscriberServiceService: SubscriberServiceService,
    private readonly parameterService: OccurenceParameterService,
    private messageService: AppMessageService,
    private readonly firmService: AsistanceFirmService,
    private cd:ChangeDetectorRef


  ) {  }

  async ngOnInit() {
    this.userName = this.authService.getUserClaim('name');
    this.surname = this.authService.getUserClaim('surname');
    this.email = this.authService.getUserClaim('email');
    this.userId = this.authService.getUserId();
    this.role = this.authService.getUserClaim('role')
    this.supplierId = this.authService.getUserClaim('supplierId')

    if (this.userId) {
      this.sc = new TaskSocketService(this.userId)
      this.sc.getCalculatedTasks().subscribe(msg => {
        this.taskValue = msg;
      });
    }

    this.getUnreadAnnouncements();

    if (this.role === 'supplier') {
      this.optionChangesInformation = JSON.parse(localStorage.getItem('optionChangesInformation') || '[]').filter((item: any) => item.supplier === this.supplierId);

    this.socketService = new OctoSupplierNotificationsService(this.supplierId);
    
      this.notificationMinutes = Number((await this.parameterService.getParameterByName('supplierServiceInfoNotificationMinutes').toPromise())?.value) || 10;
  
      this.socketService.getServiceInfo().subscribe(msg => {
        this.supplierServiceInfo = msg;
        if (this.supplierServiceInfo.length > 0) {
          const intervalMilliseconds = this.notificationMinutes * 60 * 1000;
          this.intervalId = setInterval(() => {
            this.notification();
          }, intervalMilliseconds);
        } else if (this.supplierServiceInfo.length === 0) {
          clearInterval(this.intervalId);
        }
      });
  
      this.subscriberServiceService.getServiceInfo(this.supplierId)
        .subscribe(async res => {
          this.supplierServiceInfo = res;
          if (this.supplierServiceInfo.length > 0) {
            const intervalMilliseconds = this.notificationMinutes * 60 * 1000;
            this.intervalId = setInterval(() => {
              this.notification();
            }, intervalMilliseconds);
          }
      });

      this.socketService.optionInfoExchange().subscribe((res: any) => {
        if (res && res.supplier === this.supplierId) {
          const existingData = JSON.parse(localStorage.getItem('optionChangesInformation') || '[]');
          const existingIndex = existingData.findIndex(info => info.operation.code === res.operation.code && info.operation.serviceId === res.operation.serviceId);
          
          if (existingIndex !== -1) {
            existingData.splice(existingIndex, 1);
          }
          existingData.push(res); 
          localStorage.setItem('optionChangesInformation', JSON.stringify(existingData));
      
          this.optionChangesInformation = existingData;
          this.cd.detectChanges(); 
        }
      });

     await this.startFetchingOptionData();

    } else {
      this.firmService.getLogo().subscribe(res => {
        this.firmLogo = res.logo;
      });
    }
  }

  goToTaskList() {
    this.router.navigateByUrl('/operator/tasks');
  }

  showScriptSearchDialog() {
    this.scriptSearchComponent.scriptSearchDialog = true;
  }

  goToAnnouncementAssignedList() {
    this.router.navigateByUrl('/operator/assignedAnnouncements');
  }

  getUnreadAnnouncements() {
    this.announcementService.getUnreadReceiptCount().subscribe(
      (res): any => {
        if (res) {
          this.announcementValue = res.count;

        }
      }
    )
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  changeNotesValue(noteCount: number) {
    this.noteCount = noteCount;
  }

  openNotesDialog() {
    this.notes.openNotes = true;
  }

  openSearchDialog() {
    const escapeEvent: any = document.createEvent('CustomEvent');
    escapeEvent.key = 'F4';
    escapeEvent.code = 'F4';
    escapeEvent.initEvent('keyup', true, true);
    document.dispatchEvent(escapeEvent);
  }

  notification() {
    this.boop = new Audio('assets/sounds/alarm.mp3');
    this.boop.preload = 'auto';
    if (this.boop && this.supplierServiceInfo?.length>0) {
      this.boop.pause();
      this.boop.currentTime = 0;
      this.boop.play();
    }
  }

  getServiceInfo() {
    this.router.navigate(["/supplier-operations/operation-control"], {
      state: { callServiceInfo: true },
    });
  }

  startFetchingOptionData() {
    if (this.optionInfoSubscription) {
      this.optionInfoSubscription.unsubscribe();
    }

    this.optionInfoSubscription = this.socketService.optionInfoExchange().subscribe((res: any) => {
      if (res && res.supplier === this.supplierId) {
        this.updateOptionChangesInformation(res);
      }
    });

    timer(0, 1000).subscribe(() => {
      this.socketService.optionInfoExchange().subscribe((res: any) => {
        if (res && res.supplier === this.supplierId) {
          this.updateOptionChangesInformation(res);
        }
      });
    });
  }

  updateOptionChangesInformation(res: any) {
    const existingData = JSON.parse(localStorage.getItem('optionChangesInformation') || '[]');
    const existingIndex = existingData.findIndex(info => info.operation.code === res.operation.code && info.operation.serviceId === res.operation.serviceId);

    if (existingIndex !== -1) {
      existingData.splice(existingIndex, 1);
    }

    existingData.push(res);
    localStorage.setItem('optionChangesInformation', JSON.stringify(existingData));
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.optionInfoSubscription) {
      this.optionInfoSubscription.unsubscribe();
    }
  }


  /**Opsiyon Bilgisi Değişikliğinde Bildirimin Silinme İşlemi */
  removeItem(info: any) { 
    const index = this.optionChangesInformation.findIndex(item => 
      item.operation.code === info.operation.code &&
      item.operation.serviceId === info.operation.serviceId
    );
  
    if (index > -1) {
      this.messageService.confirm({
        message: `${info.operation?.code} Hizmeti İçin Opiyon Bilgisini Okuyup Bilgiden Haberiniz Olduğunu Onaylıyor Musunuz?`,
        header: `Bilgi Mesajı Siliniyor!`,
        accept: () => {
          this.optionChangesInformation.splice(index, 1);
  
          // localStorage'a kaydet yeni dizimi kaydet
          localStorage.setItem('optionChangesInformation', JSON.stringify(this.optionChangesInformation));
  
          this.socketService.removeOptionInfo(info).subscribe(response => {
            // console.log(response)
          }, error => {
            console.error('Bildirim kaldırılırken bir hata oluştu:', error);
          });
        }
      });
    } else {
      console.error('Kayıt bulunamadı');
    }
  }
  
  
  
}
