import { Component, Input, OnInit, SecurityContext } from "@angular/core";
import { CreateAnnouncementDto } from "../../dtos/create-announcement.dto";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { AnnouncementService } from "../../services/announcement.service";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { MediaService } from "../../../../../services/mediaService/mediaService.service";

@Component({
    selector: "app-announcement-show",
    templateUrl: "./announcement-show.component.html",
    styleUrls: ["./announcement-show.component.scss"],
})
export class AnnouncementShowComponent implements OnInit {
    @Input() announcementData: CreateAnnouncementDto;
    @Input() previewFormData: any;
    @Input() readButton: boolean;
    @Input() announcementId: string;

    announcementDialog = false;

    constructor(
        private sanitizer: DomSanitizer,
        private announcementService: AnnouncementService,
        private config: DynamicDialogConfig,
        private mediaService: MediaService
    ) {}

    ngOnInit(): void {
        if (this.config.data) {
            this.announcementData = this.config.data.announcementData;
            this.announcementData.safeContent =
                this.sanitizer.bypassSecurityTrustHtml(
                    this.announcementData.content
                );
            this.readButton = this.config.data.readButton;
        }
    }

    showAnnouncementDialog(
        data: CreateAnnouncementDto,
        readButton: boolean = false
    ) {
        this.announcementDialog = true;
        this.readButton = readButton;
        this.announcementData = data;
        this.announcementData.safeContent =
            this.sanitizer.bypassSecurityTrustHtml(
                this.announcementData.content
            );
    }

    sanitizeContent(html: string): SafeHtml {
        const sanitizedHtml = this.sanitizer.sanitize(
            SecurityContext.HTML,
            html
        );
        return this.sanitizer.bypassSecurityTrustHtml(sanitizedHtml);
    }

    onReadButtonClick() {
        this.announcementService
            .updateReadReceipt(this.announcementData._id)
            .subscribe((res) => {
                this.announcementData.announcementReadUser = res;
                this.announcementDialog = false;
            });
    }

    getFile(file: any) {
        this.mediaService.getFile(file?.piid).subscribe((res) => {
            if (res) {
                this.mediaService.download({
                    url: res,
                    filename: file?.filename,
                });
            }
        });
    }

    protected readonly length = length;
}
