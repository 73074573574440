import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { permissionsMenu } from '../services/permission.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const expectedPermissions:any = permissionsMenu.find(m=> m?.link === state.url )?.permissions 
 

    if(!this.authService.isLoggedIn()){
        this.authService.logout();
        return false;
    }
    //if there is no expected Permission then allow
    if(!expectedPermissions){
      return true;
    }
    else{
        const userPermissions: any[] = localStorage.getItem("user_roles")
        ? JSON.parse(localStorage.getItem("user_roles"))
        : [];

        for (let index = 0; index < expectedPermissions.length; index++) {
            const permission:string = expectedPermissions[index];

            const hasPermission = userPermissions.find(per => (per?.permissionName?.toString().toLocaleUpperCase("tr") === permission?.toLocaleUpperCase("tr")))?.isList;
            if(!hasPermission) {
              //yetki bulunamadı
              this.router.navigate(["/accessdenied"]);
              return false;
            }
        }
    }
    return true;
  }
}
