import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UrlSerializer } from '@angular/router';
import localeTr from '@angular/common/locales/tr';
import localeTrExtra from '@angular/common/locales/extra/tr';
import { TokenInterceptor } from './services/auth/token.interceptor';
import { LowerCaseUrlSerializer } from './services/common/lowecaseurlserializer';
import { SharedModule } from './shared.module';
import { HomeComponent } from './pages/home/home.component';
import { ResetPasswordComponent } from './pages/login/reset-password/reset-password.component';
import { LoginComponent } from './pages/login/login/login.component';
import { DownloadReportComponent } from './pages/download-report/download-report.component';
import { ForgotPasswordComponent } from './pages/login/forgot-password/forgot-password.component';
import { SocketIoModule } from 'ngx-socket-io';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppSettingsService } from './services/settings/app-settings.service';
import { FileCardComponent } from './pages/home/file-card/file-card.component';
import { ServiceCardComponent } from './pages/home/service-card/service-card.component';
import { DurumlarCardComponent } from './pages/home/durumlar-card/durumlar-card.component';
import { MapComponent } from './pages/home/map/map.component';
import { OccurrenceParameterModule } from './pages/occurrence-parameter/occurrence-parameter.module';
import { LogListComponent } from './pages/log-list/log-list.component';
import { SmsLogComponent } from './pages/log-list/sms-log/sms-log.component';
import { EmailLogComponent } from './pages/log-list/email-log/email-log.component';
import { OctoNotificationsComponent } from './octo-notifications/octo-notifications.component';
import { OctoSupplierNotificationsComponent } from './octo-supplier-notifications/octo-supplier-notifications.component';
import { CelebrationComponent } from './pages/login/login/celebration/celebration.component';
import { OctopusManagerModule } from './octopus-manager/octopus-manager.module';

registerLocaleData(localeTr, 'tr-TR', localeTrExtra);

function initializeAppFactory(http: HttpClient, appSettings: AppSettingsService): () => Observable<any> {
    return () => http.get(`${environment.url}app/settings`)
        .pipe(
            tap((settings) => {
                appSettings.version = settings.version;
                appSettings.versionName = settings.versionName;
                appSettings.season = settings.season;
                appSettings.loginStrategy = settings.loginStrategy;
                appSettings.twoFactorAuthentication=settings.twoFactorAuthentication === 'true' ? true:false;
                appSettings.captchaGuard=settings.captchaGuard === 'false' ? false:true;

            })
        );
}

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        SharedModule,
        OctopusManagerModule,
        OccurrenceParameterModule,
        SocketIoModule.forRoot({
            url: environment.socketUrl ?? '/',
            options: {
                autoConnect: false,
                transports: ['websocket']
            }
        })
    ],
    declarations: [
        AppComponent,
        OctoNotificationsComponent,
        OctoSupplierNotificationsComponent,
        LoginComponent,
        HomeComponent,
        ResetPasswordComponent,
        ForgotPasswordComponent,
        DownloadReportComponent,
        FileCardComponent,
        ServiceCardComponent,
        DurumlarCardComponent,
        MapComponent,
        LogListComponent,
        SmsLogComponent,
        EmailLogComponent,
        CelebrationComponent


    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
        {
            provide: LOCALE_ID,
            useValue: 'tr-TR'
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [HttpClient, AppSettingsService],
            multi: true
        },
        DatePipe
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
