import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DefaultLayoutComponent } from '../default-layout/default-layout.component';
import { MenuItem } from 'primeng/api/menuitem';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RolesEnum } from 'src/app/domain/decorators/roles.decorator';
import { MenuService } from 'src/app/services/menu.service';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.css']
})
export class AppMenuComponent implements OnInit, OnDestroy {
    @Input() reset: boolean;
    model: any[] = [];
    userRole: string;
    openNotes: boolean = false;
    totalNote: number = 0;

    constructor(private auth: AuthService,
                private menuService: MenuService,
                private dialogService: DialogService) {
        this.userRole = this.auth.getUserRole();
    }

   async ngOnInit() {
        this.model = [];
        //const email = this.auth.getUserEmail();
        if (this.userRole === RolesEnum.admin.toString() ||
            this.userRole === RolesEnum.superAdmin.toString()) {
            this.model = [...this.model,
                    ...this.menuService.getDashboardMenu(),
                    ...this.menuService.getOperatorMenu(),
                    ...(await this.menuService.getSettingsMenu()),
                    ...(await this.menuService.getReportsMenu()),
                    ...this.menuService.getVersionDetails()
                ];
            // this.model.find(el => el.label === 'Tanımlar').items.find(el => el.label === 'Genel Tanımlar').items.unshift(
            //     {label: 'Kullanıcılar', icon: 'pi pi-fw pi-users', routerLink: ['/operator/userlist'],}
            // );
        }
        if (this.userRole === RolesEnum.moderator.toString()) {
            this.model = [...this.model,
                ...this.menuService.getOperatorMenu(),
                ... await this.menuService.getReportsMenu()];
        }
        if (this.userRole === RolesEnum.superAdmin.toString() || this.userRole === RolesEnum.admin.toString()) {
            this.model = [...this.model, ...(await this.menuService.getFinanceMenu())];
        }
        if (this.userRole === RolesEnum.user.toString()) {
            this.model = [...this.model, ...this.menuService.getOperatorMenu()];
        }
        if (this.userRole === RolesEnum.supplier.toString()) {
            this.model = [...this.model, ...(await this.menuService.getSupplierMenu())];
        }

        if(this.userRole === RolesEnum.superAdmin.toString()) {
            this.model = [...this.model, ...this.menuService.getOctopusAdminMenu()];
        }
    }

    ngOnDestroy() {
    }
}

@Component({
  /* tslint:disable:component-selector */
  selector: '[app-submenu]',
  /* tslint:enable:component-selector */
  template: `
      <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
          <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass" class="octo-menu-item"
                *ngIf="child.visible === false || (child.separator && child.separator === true) ? false : true">
              <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)"
                 *ngIf="!child.routerLink" [ngClass]="child.styleClass" class="octo-menu-link"
                 [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
                  <i [ngClass]="child.icon"></i>
                  <span>{{child.label}}</span>
                  <i class="pi pi-angle-down layout-menuitem-toggler" *ngIf="child.items"></i>
                  <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
              </a>

              <a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" *ngIf="child.routerLink"
                  [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink" class="octo-menu-link"
                  [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
                  <i [ngClass]="child.icon"></i>
                  <span>{{child.label}}</span>
                  <i class="pi pi-angle-down" *ngIf="child.items"></i>
                  <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
              </a>
              <div class="layout-menu-tooltip">
                <div class="layout-menu-tooltip-arrow"></div>
                <div class="layout-menu-tooltip-text">{{child.label}}</div>
              </div>
              <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [reset]="reset" [parentActive]="isActive(i)"
                  [@children]="(app.isSlim()||app.isHorizontal())&&root ? isActive(i) ?
                  'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'" class="octo-sub-menu" style="min-width: max-content;"></ul>
          </li>
          <li class="ui-menu-separator ui-widget-content" *ngIf="child.separator === true && (!child.visible || child.visible === true)">
      </ng-template>
  `,
  animations: [
      trigger('children', [
          state('hiddenAnimated', style({
              height: '0px'
          })),
          state('visibleAnimated', style({
              height: '*'
          })),
          state('visible', style({
              height: '*',
              'z-index': 100
          })),
          state('hidden', style({
              height: '0px',
              'z-index': '*'
          })),
          transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
          transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
      ])
  ],
  styleUrls: ['./app-menu.component.css']
})
export class AppSubMenuComponent {

  @Input() item: MenuItem;

  @Input() root: boolean;

  @Input() visible: boolean;

 _parentActive: boolean;

  _reset: boolean;

  activeIndex: number;

  constructor(public app: DefaultLayoutComponent) { }

  itemClick(event: Event, item: MenuItem, index: number)  {
      if (this.root) {
          this.app.menuHoverActive = !this.app.menuHoverActive;
      }
      // avoid processing disabled items
      if (item.disabled) {
          event.preventDefault();
          return true;
      }

      // activate current item and deactivate active sibling if any
      this.activeIndex = (this.activeIndex === index) ? null : index;

      // execute command
      if (item.command) {
          item.command({ originalEvent: event, item: item });
      }

      // prevent hash change
      if (item.items || (!item.url && !item.routerLink)) {
          setTimeout(() => {
              if (this.app.layoutMenuScrollerViewChild) {
                this.app.layoutMenuScrollerViewChild.moveBar();
              }
          }, 450);
          event.preventDefault();
      }

      // hide menu
      if (!item.items) {
          if (this.app.isHorizontal() || this.app.isSlim()) {
              this.app.resetMenu = true;
          } else {
              this.app.resetMenu = false;
          }

          this.app.overlayMenuActive = false;
          this.app.staticMenuMobileActive = false;
          this.app.menuHoverActive = !this.app.menuHoverActive;
      }
  }

  onMouseEnter(index: number) {
      if (this.root && this.app.menuHoverActive && (this.app.isHorizontal() || this.app.isSlim())
        && !this.app.isMobile() && !this.app.isTablet()) {
          this.activeIndex = index;
      }
  }

  isActive(index: number): boolean {
      return this.activeIndex === index;
  }

  @Input() get reset(): boolean {
      return this._reset;
  }

  set reset(val: boolean) {
      this._reset = val;

      if (this._reset && (this.app.isHorizontal() ||  this.app.isSlim())) {
          this.activeIndex = null;
      }
  }

  @Input() get parentActive(): boolean {
    return this._parentActive;
  }

  set parentActive(val: boolean) {
    this._parentActive = val;

    if (!this._parentActive) {
      this.activeIndex = null;
    }
  }
}
