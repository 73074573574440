import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsistanceFirmComponent } from './asistance-firm/asistance-firm.component';
import { RouterModule, Routes } from '@angular/router';
import { OctopusManagerRoutingModule } from './octopus-manager-routing.module';
import { SharedModule } from '../shared.module';



@NgModule({
  declarations: [
    AsistanceFirmComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    OctopusManagerRoutingModule,
  ],
  exports: [
    AsistanceFirmComponent
  ]
})
export class OctopusManagerModule { }
