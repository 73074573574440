<div class="header">
    Firma Yönetim
    <p-button label="Yeni Ekle" icon="pi pi-plus" [outlined]="true" (onClick)="openEditDialog()" />
</div>

<div class="list">

    <div class="firm-card" *ngFor="let firm of firms" (click)="openEditDialog(firm)">

        <div class="start-part">
            <img class="firm-logo" [src]="firm.logo">
            <div>{{firm.name}}</div>
        </div>


        <div class="end-part">
            <strong *ngIf="firm.isAdmin">
                <i class="pi pi-star-fill" style="color: goldenrod;padding-right: 2px;"></i>
                Yüksek Yetkili Firma
            </strong>
            <strong [style]="{'color': firm.licanceStatus ? 'green' : 'red'}" >
                <i class="pi pi-check" *ngIf="firm.licanceStatus"></i>
                <i class="pi pi-times" *ngIf="!firm.licanceStatus"></i>
                {{firm.licanceStatus ? 'Lisans Geçerli' : 'Lisans Geçersiz'}}
            </strong>
            <strong [style]="{'color': firm.isActive? 'green' : 'red'}" >
                <i class="pi pi-check" *ngIf="firm.isActive"></i>
                <i class="pi pi-times" *ngIf="!firm.isActive"></i>
                {{firm.isActive ? 'Firma Aktif' : 'Firma Pasif'}}
            </strong>
            <p-button label="Firma Aktifliği Değiştir" icon="pi pi-sync" [text]="true" (onClick)="toggleActivity($event, firm._id)" />
        </div>
        
    </div>

</div>


<p-dialog [visible]="editDialog" [header]="dialogHeader" [closable]="false" [modal]="true" [resizable]="false">
    
    <div class="firm-edit-container">

        <div class="firm-edit-field">
            <label>Firma Adı</label>
            <input type="text" pInputText [(ngModel)]="editData.name" />
        </div>

        <div class="firm-edit-field">
            <label>Firma Ünvanı</label>
            <input type="text" pInputText [(ngModel)]="editData.title" />
        </div>

        <div class="firm-edit-field">
            <label>VKN</label>
            <input type="text" pInputText [(ngModel)]="editData.vkn" />
        </div>

        <div class="firm-edit-field">
            <label>Vergi Dairesi</label>
            <input type="text" pInputText [(ngModel)]="editData.taxOffice" />
        </div>

        <div class="firm-edit-field logo-edit-field">
            <label for="file-upload" class="custom-file-upload">Logo Yükle</label>
            <input type="file" id="file-upload" (change)="onFileChange($event)" accept="image/*" />
            <img class="firm-logo" style="border: none;" [src]="editData.logo">

            <div style="font-size:.9rem;color:gray">* Logo boyutu 32px yüksekliğinde gösterilir.</div>
            <div style="font-size:.9rem;color:gray">* Tavsiye edilen yazı logo oranı 8x1 (örneğin 256x32 veya 1024x128)</div>
        </div>

        <div class="firm-edit-splited-field">
            <div class="splited-field">
                <p-inputSwitch [(ngModel)]="editData.isActive" />
                <label>Firma Durumu</label>
            </div>
            <div class="splited-field">
                <p-inputSwitch [(ngModel)]="editData.licanceStatus"/>
                <label>Lisans Durumu</label>
            </div>
            <div class="splited-field">
                <p-inputSwitch [(ngModel)]="editData.isAdmin"/>
                <label>Yüksek Yetkili</label>
            </div>
        </div>

        <div style="font-size:.9rem;color:gray;padding-left: .5rem;">* Yüksek yetkili firmaların kullanıcıları, tüm firmalarda işlem yapma yetkisine sahiptir.</div>

        <div class="edit-officals-field">
            <div style="display: flex;width: 100%;justify-content: space-between;align-items: center;">
                <strong>Yetkili Listesi</strong>
                <p-button label="Yetliki Ekle" icon="pi pi-plus" [text]="true" (onClick)="editData.officals.push({name: '', phone: '', email: ''})" />
            </div>
            
            <div class="edit-offical-list" [style]="{'padding-block': editData.officals.length > 0 ? '.5rem' : '0'}">
                <div class="edit-offical" *ngFor="let offical of editData.officals; let index = index">
                    <input type="text" placeholder="Yetkili Adı" pInputText [(ngModel)]="offical.name" />
                    <input type="text" placeholder="Telefon" pInputText [(ngModel)]="offical.phone" />
                    <input type="text" placeholder="Eposta" pInputText [(ngModel)]="offical.email" />
                    <i class="pi pi-trash offical-delete-icon" (click)="editData.officals.splice(index, 1)"></i>
                </div>
            </div>

        </div>

    </div>


    <ng-template pTemplate="footer">
        <div class="firm-edit-footer">
            <p-button label="İptal" [text]="true" (onClick)="editDialog = false" />
            <p-button label="Kaydet" icon="pi pi-save" severity="success" (onClick)="save()" />
        </div>
    </ng-template>

</p-dialog>
