import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CaseInsensitiveMatcher } from '../services/common/caseinsensitiveurlmatcher';
import { AsistanceFirmComponent } from './asistance-firm/asistance-firm.component';
import { PermissionsGuard } from '../guards/PermissionsGuard';

const routes: Routes = [
  { matcher: CaseInsensitiveMatcher('firms'), component: AsistanceFirmComponent, canActivate: [PermissionsGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OctopusManagerRoutingModule { }
