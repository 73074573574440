<div
    class="p-dialog-header"
    [ngClass]="{
        'background-warning': announcementData.announcementType === 'warning',
        background: announcementData.announcementType === 'information'
    }"
>
    <i
        class="pi p-mr-2 p-ml-2"
        [ngClass]="{
            'pi-exclamation-triangle p-icon-warning':
                announcementData.announcementType === 'warning',
            'pi-info-circle p-icon-info':
                announcementData.announcementType === 'information'
        }"
    ></i>

    <div class="container">
        <div class="info">
            <div class="label">
                <span class="pi pi-id-card" style="font-weight: 700"> </span>
                Duyuru No
            </div>
            <div class="value">
                <b>:</b> {{ announcementData.announcementNo }}
            </div>
        </div>

        <div class="info">
            <div class="label">
                <span class="pi pi-calendar" style="font-weight: 700"> </span>
                Geçerlilik Tarihi
            </div>
            <div class="value">
                <b>:</b>
                {{ announcementData.startDate | date : "dd.MM.yyyy HH:mm" }}
                -
                {{ announcementData.endDate | date : "dd.MM.yyyy HH:mm" }}
            </div>
        </div>
    </div>
</div>
<div class="p-dialog-content">
    <div class="container">
        <div class="subject">
            {{ announcementData.subject }}
        </div>
        <p-divider></p-divider>
        <div class="ql-editor">
            <div
                class="content"
                [innerHTML]="announcementData.safeContent"
            ></div>
        </div>
    </div>
</div>

<div class="p-field p-col-12 p-md-12" *ngIf="announcementData?.files.length>0">
    <hr>
    <h3>Ekler</h3>
    <div class="files"
         *ngFor="let file of announcementData?.files">
        <span>{{file.creationDate | date:'dd.MM.yyyy HH:mm'}}</span>
        <a (click)="getFile(file)" class="p-fileupload-filename">{{file.filename}}</a>
        <span>{{file.createdByName}}</span>
    </div>
</div>

<div class="p-dialog-footer" *ngIf="readButton">
    <p-button
        *ngIf="!announcementData.announcementReadUser"
        icon="pi pi-check"
        (click)="onReadButtonClick()"
        label="Okudum, Anladım"
        [text]="true"
    ></p-button>
</div>

<!--<p-dialog
    [(visible)]="announcementData"
    [modal]="true"
    [draggable]="false"
    [style]="{ width: '50vw' }"
>
    <ng-template pTemplate="header">
        <div class="container background">
            <div class="info">
                <div class="label">
                       <span class="pi pi-id-card" style="font-weight: 700">
                       </span>
                    Duyuru No
                </div>
                <div class="value">
                    <b>:</b> {{ announcementData.announcementNo }}
                </div>
            </div>

            <div class="info">
                <div class="label">
                       <span class="pi pi-calendar" style="font-weight: 700">
                       </span>
                    Geçerlilik Tarihi
                </div>
                <div class="value">
                    <b>:</b>
                    {{
                    announcementData.startDate
                        | date : "dd.MM.yyyy HH:mm"
                    }}
                    -
                    {{
                    announcementData.endDate | date : "dd.MM.yyyy HH:mm"
                    }}
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="container">
            <div class="subject">
                {{ announcementData.subject }}
            </div>
            <p-divider></p-divider>

            <div class="content"  [innerHTML]="safeContent">

            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="footer" *ngIf="readButton">
        <p-button
            *ngIf="!announcementData.announcementReadUser"
            icon="pi pi-check"
            (click)="onReadButtonClick()"
            label="Okudum, Anladım"
            styleClass="p-button-text"
        ></p-button>
    </ng-template>
</p-dialog>-->
