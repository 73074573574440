<div class="p-m-0" style="height: 100vh; overflow: hidden; margin-top: 0px ;">
    <div class="p-grid" style="height: 100%; margin-top: 0px;">
        <div class="p-col-12 p-md-4 login-form-background" >
            <div class="p-col-12">
                <div class="hosgeldiniz">
                    <img src="../../../../assets/images/octopus/hosgeldiniz.png" alt="">
                     <hr>
                </div>
            </div>
            <div class="col-md-12 p-mb-3 message">
                <p-message *ngIf="shownMessage" severity="info"
                    text="Oturum zaman aşımı süresi doldu. Lütfen tekrar giriş yapınız."></p-message>
                <p-message *ngIf="errorMessage" severity="error" text="Kullanıcı Adı ya da Şifre Hatalı"></p-message>
            </div>

            <form (submit)="submit()">
                <div *ngIf="!showCodePanel; else elseBlock" class="grid">
                    <div class="p-col-12 p-fluid">
                        <label>{{ userNameCaption }}</label>
                        <input [(ngModel)]="email" name="email" pInputText type="text" [placeholder]="userNameCaption">
                    </div>
                    <div class="p-col-12 p-fluid">
                        <label>Şifre</label>
                        <div class="password-wrapper" style="position: relative; display: flex; align-items: center;">
                            <input [(ngModel)]="password" name="password" pInputText [type]="passwordFieldType" placeholder="Şifre" />
                            <i class="pi" [ngClass]="passwordFieldType === 'password' ? 'pi-eye' : 'pi-eye-slash'"
                               style="position: absolute; right: 10px; cursor: pointer;" 
                               (click)="togglePasswordVisibility()">
                            </i>
                        </div>                
                    </div>
                    <div class="p-grid p-mt-2" style="align-items: center;">
                        <div class="p-col-6">
                            <div style="margin-left: 1.5rem;">
                                <p-checkbox label="Beni Hatırla" [(ngModel)]="rememberMe" [binary]="true" name="rememberMe"></p-checkbox>
                            </div>
                        </div>
                        <div class="p-col-6" style="display: flex; justify-content: flex-end;">
                            <div style="margin-right: 1.5rem;">
                                <button pButton label="Giriş" icon="pi pi-check" type="submit"
                                    [disabled]="controlButtonDisable()"></button>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12" *ngIf="appSettings.captchaGuard">
                        <app-recaptcha (onResponse)="showCaptchaResponse($event)" #recapthca></app-recaptcha>
                    </div>
                    <!-- <div class="p-col-12 forgot-password">
                        <a routerLink="/forgot-password" class="with-lines">Şifremi unuttum!</a>
                    </div> -->
                </div>

                <ng-template #elseBlock>
                    <div style="margin-bottom:1rem">
                        <h4>SMS ile gönderilen kodu giriniz</h4>
                        <ng-otp-input  class="otp-input-style" #ngOtpInput  (onInputChange)="onOtpChange($event)"  [config]="{length:6,allowNumbersOnly:true}"></ng-otp-input>

                    </div>
                    <div style="display: flex; justify-content: center;margin-bottom:1rem;">
                        <octo-countdown  [counter]="59" (onCounterFinish)="timeFinish()" #countdown></octo-countdown>
                    </div>

                    <div class="p-col-12 text-center message" [style.color]="otpCodeMessage.success ? 'green':'red'" [hidden]="otpCodeMessage.success===undefined">
                        {{ otpCodeMessage.message }}
                    </div>
                    <div style="display: flex; justify-content: center">
                        <button pButton label="Giriş Yap" type="button" icon="pi pi-check" (click)="signIn()"></button>
                    </div>
                    <div style="display: flex; justify-content: flex-end">

                        <button pButton [label]="'Yeni Kod Gönder'"type="button"class="p-button-text"(click)="getOtpCode()"></button>
                    </div>
                </ng-template>

            </form>
        </div>

        <div class="p-col-12 p-md-8 desktop-only" style="padding: 0px; ">
            <div class="login-background">
                <div class="p-grid" style="margin-top: 0px;">
                    <div class="p-col-12 logo">
                        <img src="../../../../assets/images/octopus/logo-beyaz.png" style="max-width: 45%;"alt="">
                    </div>
                    <div class="p-col-12 idea-logo">
                        <img src="../../../../assets/images/octopus/idea-logo.png" style="max-width: 10%;"alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>