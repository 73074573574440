import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';
import { KeyFilterModule } from 'primeng/keyfilter';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenubarModule } from 'primeng/menubar';
import { ToolbarModule } from 'primeng/toolbar';
import { BlockUIModule } from 'primeng/blockui';
import {StepsModule} from 'primeng/steps';
import { SkeletonModule } from 'primeng/skeleton';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CarouselModule } from 'primeng/carousel';
import { TabViewModule } from 'primeng/tabview';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { ListboxModule } from 'primeng/listbox';
import {ProgressBarModule} from 'primeng/progressbar';
import { PanelModule } from 'primeng/panel';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { DataViewModule } from 'primeng/dataview';
import { FieldsetModule } from 'primeng/fieldset';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DialogService, DynamicDialogConfig, DynamicDialogModule } from 'primeng/dynamicdialog';
import { AppTopbarComponent } from './layout/app-topbar/app-topbar.component';
import { AppMenuComponent, AppSubMenuComponent } from './layout/app-menu/app-menu.component';
import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { ErrorLayoutComponent } from './layout/error-layout/error-layout.component';
import { ResourceNotFoundComponent } from './layout/resource-not-found/resource-not-found.component';
import { AccessDeniedComponent } from './layout/access-denied/access-denied.component';
import { AccordionModule } from 'primeng/accordion';
import { ChartModule } from 'primeng/chart';
import { PaginatorModule } from 'primeng/paginator';
import { SidebarModule } from 'primeng/sidebar';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppBreadcrumbComponent } from './layout/app-breadcrumb/app-breadcrumb.component';
import { AppFooterComponent } from './layout/app-footer/app-footer.component';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { SlideMenuModule } from 'primeng/slidemenu';
import { ContextMenuModule } from 'primeng/contextmenu';
import { BadgeModule } from 'primeng/badge';
import { SubmitButtonDirective } from './directives/submit-button.directive';
import { TooltipModule } from 'primeng/tooltip';
import { DividerModule } from 'primeng/divider';
import {SelectButtonModule} from 'primeng/selectbutton';
import { TagModule } from 'primeng/tag';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownDirective } from './directives/filter-dropdown.directive';
import { TimelineModule } from 'primeng/timeline';
import { EmailService } from './services/email.service';
import {EditorModule} from 'primeng/editor';
import { AddressAutocomplete } from './components/adress-autocomplete/adress-autocomplete.component';
import { SecurePhotoPipe } from './pipes/secure-photo.pipe';
import { MenuModule } from 'primeng/menu';
import {ChipsModule} from 'primeng/chips';
import {ImageModule} from 'primeng/image';
import {GalleriaModule} from 'primeng/galleria';
import { CountdownDirective } from './directives/countdown.directive';
import { FileViewComponent } from './components/file-view/file-view.component';
import { ChipModule } from 'primeng/chip';
import { ChipListComponent } from './components/chip-list/chip-list.component';
import { AssuranceDisplayComponent } from './components/assurance-display/assurance-display.component';
import { AssuranceAsButtonComponent } from './components/assurance-display/assurance-as-button/assurance-as-button.component';
import {KnobModule} from 'primeng/knob';
import { UserInfoCardComponent } from './components/user-info-card/user-info-card.component';
import { ControlMarkComponent } from './components/control-mark/control-mark.component';
import { PriceSummaryComponent } from './components/price-summary/price-summary.component';
import { ServiceFotoViewComponent } from './components/service-foto-view/service-foto-view.component';
import { FileFotoViewComponent } from './components/file-foto-view/file-foto-view.component';
import { OctoTableComponent } from './components/octo-table/octo-table.component';
import { OctoTableFormComponent } from './components/octo-table/components/octo-table-form/octo-table-form.component';
import { DataFieldComponent } from './pages/home/stats/data-field/data-field.component';
import { StatsComponent } from './pages/home/stats/stats.component';
import { OctoTableFiltersComponent } from './components/octo-table/components/octo-table-filters/octo-table-filters.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { CountDownComponent } from './components/countdown/countdown.component';
import { OctoQuestionsComponent } from './components/octo-questions/octo-questions.component';
import { StatusDropdownComponent } from './components/filters/status-dropdown/status-dropdown.component';
import { StatusBadgeComponent } from './components/status-badge/status-badge.component';
import { StatusChangePopupComponent } from './components/status-badge/status-change-popup/status-change-popup.component';
import { CencelRejectPopupComponent } from './components/status-badge/status-change-popup/cencel-reject-popup/cencel-reject-popup.component';
import {TabMenuModule} from 'primeng/tabmenu';
import { OrderListModule } from 'primeng/orderlist';
import { SupplierVehicleCustomizationComponent } from './pages/settings/supplier/supplier-management/supplier-vehicle-customization/supplier-vehicle-customization.component';
import { SupplierBranchComponent } from './pages/settings/supplier/supplier-management/supplier-branch/supplier-branch.component';
import { TableColSettingsComponent } from './components/table-col-settings/table-col-settings.component';
import { AddressComponent } from './components/address/address.component';
import { OctoMapComponent } from './components/octo-map/octo-map.component';
import { CityTownPickerComponent } from './components/city-town-picker/city-town-picker.component';
import { SelfNotesComponent } from './components/self-notes/self-notes.component';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { SupplierStaffDialogComponent } from './components/supplier-staff-list/supplier-staff-dialog/supplier-staff-dialog.component';
import { SupplierStaffListComponent } from './components/supplier-staff-list/supplier-staff-list.component';
import { SupplierCarListComponent } from './components/supplier-car-list/supplier-car-list.component';
import { SupplierCarDialogComponent } from './components/supplier-car-list/supplier-car-dialog/supplier-car-dialog.component';
import { OctoFieldComponent } from './components/payment/octo-field/octo-field.component';
import { PaymentPopupComponent } from './components/payment/payment-popup/payment-popup.component';
import { PaymentHeaderComponent } from './components/payment/payment-popup/payment-header/payment-header.component';
import { OctoAcordionComponent } from './components/payment/octo-acordion/octo-acordion.component';
import { SureModalComponent } from './components/sure-modal/sure-modal.component';
import { ZeplinLogsTimelineComponent } from './components/zeplin-logs-timeline/zeplin-logs-timeline.component';
import { ZeplinLogTypePipe } from './pipes/zeplin-log.type.pipe';
import { ZeplinLogDetailsComponent } from './components/zeplin-logs-timeline/zeplin-log-details/zeplin-log-details.component';
import { OctoAnswerComponent } from './components/octo-answer/octo-answer.component';
import {RatingModule} from 'primeng/rating';
import { PaymentItemComponent } from './components/payment/payment-popup/payment-item/payment-item.component';
import { PaymentListComponent } from './components/payment/payment-list/payment-list.component';
import { AboutComponent } from './components/about/about.component';
import { PaymentStatusBadgeComponent } from './components/payment/payment-status-badge/payment-status-badge.component';
import { OctopusLogsComponent } from './components/octopus-logs/octopus-logs.component';
import { PaymentSummaryComponent } from './components/payment/payment-popup/payment-summary/payment-summary.component';
import { AsSurveysComponent } from './components/as-surveys/as-surveys.component';
import { PaymentOperationsComponent } from './pages/finance/payment-operations/payment-operations.component';
import { PaymentOperationsHeaderComponent } from './pages/finance/payment-operations/payment-operations-header/payment-operations-header.component';
import { PaymentOperationsListComponent } from './pages/finance/payment-operations/payment-operations-list/payment-operations-list.component';
import { PaymentLineComponent } from './pages/finance/payment-operations/payment-operations-list/payment-line/payment-line.component';
import { PaymentActionPanelComponent } from './pages/finance/payment-operations/payment-action-panel/payment-action-panel.component';
import { PaymentInfoDetailsDisplayComponent } from './pages/finance/payment-operations/payment-action-panel/payment-info-details-display/payment-info-details-display.component';
import { PaymentsToBillPopupComponent } from './pages/finance/payment-operations/payment-action-panel/payments-to-bill-popup/payments-to-bill-popup.component';
import { StatusUpdateReportPopupComponent } from './pages/finance/payment-operations/payment-action-panel/status-update-report-popup/status-update-report-popup.component';
import { PaymentObjectionsComponent } from './pages/finance/payment-operations/payment-objections/payment-objections.component';
import { AnnouncementShowComponent } from './pages/operator/announcement/components/announcement-show/announcement-show.component';
import { BillListComponent } from './pages/finance/bill-list/bill-list.component';
import { BillDetailsComponent } from './components/payment/bill-details/bill-details.component';
import { FileUploadModule } from 'primeng/fileupload';
import { OctoMapAdvancedComponent } from './components/octo-map-advanced/octo-map-advanced.component';
import { MapComponent } from './pages/map/map.component';
import { UserRosetteComponent } from './components/user-rosette/user-rosette.component';
import { ScriptSearchComponent } from './pages/operator/script/components/script-search/script-search.component';
import { SavedFiltersV2Component } from './components/saved-filters-v2/saved-filters-v2.component';
import { RecaptchaComponent } from './components/recaptcha/recaptcha.component';
import { PaymentConsensusComponent } from './pages/finance/payment-consensus/payment-consensus.component';
import { CityAndTownComponent } from './components/city-and-town/city-and-town.component';
import { NumberCommaFormatPipe } from './pipes/number-comma.pipe';
import { SearchComponent } from './components/search/search.component';
import { MoneyFormatPipe } from './pipes/money.pipe';
import { OctoPanelComponent } from './components/octo-panel/octo-panel.component';
import { OctopanelDirective } from './components/octo-panel/octopanel.directive';
import { SummaryDetailsComponent } from './components/octo-panel/octo-summary-details/octo-summary-details.component';
import { ExpectedPaymentComponent } from './pages/supplier/expented-payment/expected-payment.component';
import { RegionInfoComponent } from './components/region-info/region-info.component';
import { BillDialogComponent } from './pages/finance/bill-list/bill-dialog/bill-dialog.component';
import { PaymentControlComponent } from './pages/finance/payment-control/payment-control.component';
import { PaymentControlHeaderComponent } from './pages/finance/payment-control/payment-control-header/payment-control-header.component';
import { PaymentControlActionPanelComponent } from './pages/finance/payment-control/payment-control-action-panel/payment-control-action-panel.component';
import { MoneyDisplayBadgeComponent } from './pages/finance/payment-control/money-display-badge/money-display-badge.component';
import { PaymentFileServiceDetailsComponent } from './pages/finance/payment-control/payment-file-service-details/payment-file-service-details.component';
import { DocumentPreviewComponent } from './components/document-preview/document-preview.component';
import { SupplierQuotaSettingComponent } from './components/supplier-quota-setting/supplier-quota-setting.component';
import { OctoLoadingComponent } from './octo-loading/octo-loading.component';
import { FilterAccordionComponent } from './pages/operator/reporter/sla-categories-dashboard/filter-accordion/filter-accordion.component';
import { SuppliersUsersComponent } from './pages/settings/supplier/supplier-management/suppliers-users/suppliers-users.component';
import { UserCreatePageComponent } from './pages/user-management/user-create-page/user-create-page.component';
import { FileHeaderWarningsComponent } from './pages/operator/file/file-header-warnings/file-header-warnings.component';
import { ServiceExpectedComponent } from './pages/supplier/expented-payment/service-expected/service-expected.component';
import { SupplierExpectedComponent } from './pages/supplier/expented-payment/supplier-expected/supplier-expected.component';
import { AppUserGuideComponent } from './layout/app-user-guide/app-user-guide.component';
import { PaymentDetailLogsComponent } from './components/payment/payment-popup/paymnet-detail-logs/payment-detail-logs.component';
import { CampaignsComponent } from './pages/campaigns/campaigns.component';
import { FileControleInputComponent } from './pages/operator/file-controle-input/file-controle-input.component';
import { ProfileCardComponent } from './components/profile-card/profile-card.component';
import { AvatarModule } from 'primeng/avatar';
import { UserEditComponent } from './pages/user-management/user-list/user-edit/user-edit.component';
import { UserListComponent } from './pages/user-management/user-list/user-list.component';
import { UserPasswordChangeComponent } from './pages/user-management/user-list/user-password-change/user-password-change.component';
import { OctoServiceLocationsMapComponent } from './pages/octo-service-locations-map/octo-service-locations-map.component';
// import { PaymentDetailLogsComponent } from './components/payment/payment-popup/paymnet-detail-logs/paymnet-detail-logs.component';

@NgModule({
    declarations: [
        OctoMapComponent,
        /* Libraries - Octo Table */
        OctoTableComponent,
        OctoTableFormComponent,
        OctoTableFiltersComponent,
        /* --------- */
        StatusBadgeComponent,
        StatusChangePopupComponent,
        CencelRejectPopupComponent,
        /* --------- */
        /* Libraries - Octo Questions */
        OctoQuestionsComponent,
        /* --------- */
        /* Libraries - Home Page */
        StatsComponent,
        DataFieldComponent,
        /* --------- */
        /* Libraries - Table Col Settings */
        TableColSettingsComponent,
        /* --------- */
        /* Libraries - Table Col Settings */
        SavedFiltersV2Component,
        /* --------- */
        OctoPanelComponent,
        SummaryDetailsComponent,
        SearchComponent,
        SelfNotesComponent,
        OctoFieldComponent,
        OctoAcordionComponent,
        AboutComponent,

        /*  Payments */
        PaymentPopupComponent,
        PaymentSummaryComponent,
        PaymentStatusBadgeComponent,
        PaymentHeaderComponent,
        PaymentItemComponent,
        PaymentListComponent,
        BillListComponent,
        BillDialogComponent,
        BillDetailsComponent,
        PaymentOperationsComponent,
        PaymentConsensusComponent,
        PaymentControlComponent,
        PaymentControlHeaderComponent,
        MoneyDisplayBadgeComponent,
        PaymentFileServiceDetailsComponent,
        PaymentControlActionPanelComponent,
        PaymentOperationsHeaderComponent,
        PaymentOperationsListComponent,
        PaymentActionPanelComponent,
        StatusUpdateReportPopupComponent,
        PaymentsToBillPopupComponent,
        PaymentObjectionsComponent,
        PaymentInfoDetailsDisplayComponent,
        PaymentLineComponent,
        //////////////

        CityTownPickerComponent,

        AppBreadcrumbComponent,
        AppFooterComponent,
        AppMenuComponent,
        AppTopbarComponent,
        AppSubMenuComponent,
        DefaultLayoutComponent,
        LoginLayoutComponent,
        ErrorLayoutComponent,
        ResourceNotFoundComponent,
        AccessDeniedComponent,

        FileViewComponent,
        ChipListComponent,
        UserInfoCardComponent,
        ControlMarkComponent,
        PriceSummaryComponent,

        AssuranceDisplayComponent,
        AssuranceAsButtonComponent,
        AddressAutocomplete,
        AddressComponent,
        CountDownComponent,
        StatusDropdownComponent,
        SecurePhotoPipe,

        /* directives */
        OctopanelDirective,
        SubmitButtonDirective,
        DropdownDirective,
        CountdownDirective,
        ServiceFotoViewComponent,
        FileFotoViewComponent,
        SupplierVehicleCustomizationComponent,
        SupplierBranchComponent,

        SupplierStaffListComponent,
        SupplierStaffDialogComponent,
        SupplierCarListComponent,
        SuppliersUsersComponent,
        UserCreatePageComponent,
        SureModalComponent,
        ZeplinLogsTimelineComponent,
        ZeplinLogTypePipe,
        ZeplinLogDetailsComponent,
        SupplierCarDialogComponent,
        OctoAnswerComponent,
        OctopusLogsComponent,
        AsSurveysComponent,

        AnnouncementShowComponent,
        OctoMapAdvancedComponent,
        MapComponent,
        UserRosetteComponent,
        ScriptSearchComponent,
        RecaptchaComponent,
        CityAndTownComponent,
        ExpectedPaymentComponent,
        ServiceExpectedComponent,
        SupplierExpectedComponent,
        NumberCommaFormatPipe,
        MoneyFormatPipe,
        RegionInfoComponent,
        DocumentPreviewComponent,
        SupplierQuotaSettingComponent,
        OctoLoadingComponent,
        FilterAccordionComponent,
        StatusDropdownComponent,
        AppUserGuideComponent,
        FileHeaderWarningsComponent,
        PaymentDetailLogsComponent,
        CampaignsComponent,
        FileControleInputComponent,
        ProfileCardComponent,
        UserEditComponent,
        UserPasswordChangeComponent,
        UserListComponent,
        OctoServiceLocationsMapComponent,
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        /* primeng */
        ScrollPanelModule,
        MenubarModule,

        DropdownModule,
        SelectButtonModule,
        InputTextModule,
        OrderListModule,
        CalendarModule,
        RadioButtonModule,
        ButtonModule,
        TableModule,
        DataViewModule,
        FieldsetModule,
        CardModule,
        AutoCompleteModule,
        MessagesModule,
        MessageModule,
        DialogModule,
        KeyFilterModule,
        InputMaskModule,
        InputSwitchModule,
        ConfirmDialogModule,
        ToolbarModule,
        BlockUIModule,
        ProgressSpinnerModule,
        CarouselModule,
        ChipsModule,
        ToastModule,
        TabViewModule,
        OverlayPanelModule,
        InputTextareaModule,
        CheckboxModule,
        MultiSelectModule,
        ProgressBarModule,
        ListboxModule,
        PanelModule,
        AccordionModule,
        SplitButtonModule,
        ChartModule,
        DynamicDialogModule,
        PaginatorModule,
        SidebarModule,
        ConfirmPopupModule,
        SlideMenuModule,
        ContextMenuModule,
        MenuModule,
        BadgeModule,
        TooltipModule,
        DividerModule,
        TagModule,
        InputNumberModule,
        TimelineModule,
        EditorModule,
        ImageModule,
        GalleriaModule,
        ChipModule,
        TabMenuModule,
        SkeletonModule,
        TriStateCheckboxModule,

        /* GoogleAutoComplete */
        // Herhangi bir referans bulunamadığı için kapatıldı
        //GooglePlaceModule,

        /*NgOtpInputModule */
        NgOtpInputModule,
        RatingModule,
        FileUploadModule,
        AvatarModule,
    ],
    exports: [
        ExpectedPaymentComponent,
        ServiceExpectedComponent,
        SupplierExpectedComponent,
        DefaultLayoutComponent,
        LoginLayoutComponent,
        AppMenuComponent,
        OctopanelDirective,
        SubmitButtonDirective,
        DropdownDirective,
        CountdownDirective,
        FilterAccordionComponent,
        /* Libraries */
        OctoTableComponent,
        StatsComponent,
        DataFieldComponent,
        TableColSettingsComponent,
        SavedFiltersV2Component,
        OctoQuestionsComponent,
        StatusBadgeComponent,
        StatusChangePopupComponent,
        CencelRejectPopupComponent,
        CityTownPickerComponent,
        SearchComponent,
        OctoPanelComponent,
        /* --------- */
        FileViewComponent,
        ChipListComponent,
        AddressAutocomplete,
        AddressComponent,
        CountDownComponent,
        UserInfoCardComponent,
        ControlMarkComponent,
        PriceSummaryComponent,
        StatusDropdownComponent,
        AssuranceDisplayComponent,
        AssuranceAsButtonComponent,

        UserEditComponent,
        UserPasswordChangeComponent,
        UserListComponent,

        SecurePhotoPipe,
        ServiceFotoViewComponent,
        FileFotoViewComponent,
        SupplierStaffListComponent,
        SupplierStaffDialogComponent,
        SupplierCarListComponent,
        SuppliersUsersComponent,
        UserCreatePageComponent,
        SupplierCarDialogComponent,
        FileHeaderWarningsComponent,
        /* primeng */
        ScrollPanelModule,
        MenubarModule,
        DropdownModule,
        InputTextModule,
        CalendarModule,
        OrderListModule,
        RadioButtonModule,
        ButtonModule,
        SelectButtonModule,
        TableModule,
        DataViewModule,
        FieldsetModule,
        CardModule,
        AutoCompleteModule,
        MessagesModule,
        MessageModule,
        StepsModule,
        DialogModule,
        KeyFilterModule,
        InputMaskModule,
        InputSwitchModule,
        ChipsModule,
        ConfirmDialogModule,
        BlockUIModule,
        ToolbarModule,
        ProgressBarModule,
        CarouselModule,
        ToastModule,
        SummaryDetailsComponent,
        TabViewModule,
        OverlayPanelModule,
        FileUploadModule,
        InputTextareaModule,
        CheckboxModule,
        MultiSelectModule,
        ListboxModule,
        PanelModule,
        AccordionModule,
        SidebarModule,
        SplitButtonModule,
        ChartModule,
        DynamicDialogModule,
        PaginatorModule,
        ConfirmPopupModule,
        SlideMenuModule,
        SkeletonModule,
        ContextMenuModule,
        MenuModule,
        BadgeModule,
        TooltipModule,
        DividerModule,
        TagModule,
        InputNumberModule,
        TimelineModule,
        EditorModule,
        ImageModule,
        GalleriaModule,
        ChipModule,
        KnobModule,
        TriStateCheckboxModule,
        TabMenuModule,
        SupplierVehicleCustomizationComponent,
        SelfNotesComponent,
        OctoFieldComponent,
        OctoAcordionComponent,
        AboutComponent,

        /*  Payments */
        PaymentPopupComponent,
        PaymentSummaryComponent,
        PaymentStatusBadgeComponent,
        PaymentHeaderComponent,
        PaymentListComponent,
        PaymentItemComponent,
        BillListComponent,
        BillDialogComponent,
        BillDetailsComponent,
        PaymentOperationsComponent,
        PaymentConsensusComponent,
        PaymentControlComponent,
        PaymentControlHeaderComponent,
        MoneyDisplayBadgeComponent,
        PaymentFileServiceDetailsComponent,
        PaymentControlActionPanelComponent,
        PaymentOperationsHeaderComponent,
        PaymentOperationsListComponent,
        PaymentActionPanelComponent,
        StatusUpdateReportPopupComponent,
        PaymentsToBillPopupComponent,
        PaymentObjectionsComponent,
        PaymentInfoDetailsDisplayComponent,
        PaymentLineComponent,
        //////////////
        // ExpentedTableComponent,

        SupplierBranchComponent,
        TriStateCheckboxModule,

        /* GooglePlaceAutoComplement */
        //GooglePlaceModule,

        /*NgOtpInputModule */
        NgOtpInputModule,

        OctoMapComponent,
        SureModalComponent,
        ZeplinLogsTimelineComponent,
        ZeplinLogDetailsComponent,
        ZeplinLogTypePipe,
        OctoAnswerComponent,
        RatingModule,
        OctopusLogsComponent,
        AsSurveysComponent,

        AnnouncementShowComponent,
        OctoMapAdvancedComponent,
        MapComponent,
        UserRosetteComponent,
        RecaptchaComponent,
        CityAndTownComponent,
        NumberCommaFormatPipe,
        MoneyFormatPipe,
        RegionInfoComponent,
        DocumentPreviewComponent,
        SupplierQuotaSettingComponent,
        OctoLoadingComponent,
        PaymentDetailLogsComponent,
        CampaignsComponent,
        FileControleInputComponent

    ],
    providers: [
        ConfirmationService,
        MessageService,
        DialogService,
        DynamicDialogConfig,
        EmailService,
        SecurePhotoPipe,
        ZeplinLogTypePipe,
        NumberCommaFormatPipe,
        MoneyFormatPipe
    ],
})
export class SharedModule {}
