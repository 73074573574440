import { Component, Input } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { AsistanceFirmService } from '../../octopus-manager/asistance-firm/services/asistance-firm.service';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardComponent {
  userName:string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  asistanceFirm:string;
  asistanceFirmaName:string;
  asistanceFirmaLogo:string;



  userInfo = [
    { icon: 'pi pi-user', value: '' },
    { icon: 'pi pi-envelope', value: '' },
    { icon: 'pi pi-phone', value: '' }
  ];

  constructor(
    public authService: AuthService,
    public asistanceFirmService:AsistanceFirmService
  ) {  }

  async ngOnInit() {
    this.userName = this.authService.getUserClaim('userName');
    this.name = this.authService.getUserClaim('name');
    this.surname = this.authService.getUserClaim('surname');
    this.email = this.authService.getUserClaim('email');
    this.phone = this.authService.getUserClaim('phone');
    this.asistanceFirm = this.authService.getUserClaim('asistanceFirm');

    this.asistanceFirmService.getOne(this.asistanceFirm).subscribe(
      (res) => {
        this.asistanceFirmaName = res.name;
        this.asistanceFirmaLogo = res.logo;
      },
      (error) => {
        console.error('Error:', error);
      }
    );

    this.userInfo = [
      { icon: 'pi pi-id-card', value:  this.userName },
      { icon: 'pi pi-envelope', value: this.email },
      { icon: 'pi pi-phone', value: this.phone }
    ];

  }
}
