import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { IAsistanceFirm } from "../utils/asistans-firm.interdace";

@Injectable({
    providedIn: 'root'
  })
  export class AsistanceFirmService {
  
    constructor(private http: HttpClient) {}
  
    create(data: IAsistanceFirm) {
      return this.http.post<IAsistanceFirm>(`${environment.url}asistanceFirms`, data);
    }
  
    getAll() {
        return this.http.get<IAsistanceFirm[]>(`${environment.url}asistanceFirms`);
    }

    update(data: IAsistanceFirm) {
        const id = data._id;
        return this.http.patch<IAsistanceFirm>(`${environment.url}asistanceFirms/${id}`, data);
    }

    toggleActivity(id: string) {
        return this.http.get<IAsistanceFirm>(`${environment.url}asistanceFirms/toggleActivity/${id}`);
    }
    
    getOne(id: string) {
      return this.http.get<IAsistanceFirm>(`${environment.url}asistanceFirms/firmInfo/${id}`);
    }

    getLogo() {
      return this.http.get<{logo: string}>(`${environment.url}asistanceFirms/firmLogo`);
    }

  }
  