import { Component } from '@angular/core';
import { AsistanceFirmService } from './services/asistance-firm.service';
import { IAsistanceFirm } from './utils/asistans-firm.interdace';
import { UploadEvent } from 'primeng/fileupload';

@Component({
  selector: 'app-asistance-firm',
  templateUrl: './asistance-firm.component.html',
  styleUrls: ['./asistance-firm.component.scss']
})
export class AsistanceFirmComponent {

  firms: IAsistanceFirm[] = [];

  editData: IAsistanceFirm = this.getEmptyData();
  editDialog: boolean = false;
  dialogHeader: string = '';

  constructor(private readonly service: AsistanceFirmService) {}

  ngOnInit(): void {
    this.getData();
  }

  private getData() {
    this.service.getAll().subscribe( res => {
      this.firms = res;
    });
  }

  openEditDialog(editData?: IAsistanceFirm) {
    this.editData = editData ? {...editData, officals: [...editData.officals]} : this.getEmptyData();
    this.dialogHeader = editData ? '['+editData.name+'] Düzenleme' : 'Yeni Firma Tanımlama';
    this.editDialog = true;
  }

  private getEmptyData(): IAsistanceFirm {
    return {
      name: '',
      title: '',
      isActive: true,
      isAdmin: false,
      logo: '',
      officals: [],
      vkn: '',
      taxOffice: '',
      licanceStatus: true,
    }
  }

  save() {
    const id = this.editData._id;
    if(id) {
      this.service.update(this.editData).subscribe( res => {
        this.getData();
        this.editDialog = false;
      });
    } else {
      this.service.create(this.editData).subscribe( res => {
        this.getData();
        this.editDialog = false;
      });
    }
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result as string;
      this.editData.logo = base64String;
    };

    if(file) {
      reader.readAsDataURL(file);
    }
  }

  toggleActivity(event: any, id: string) {
    event.stopPropagation();
    this.service.toggleActivity(id).subscribe(res => {
      this.getData();
    });
  }

}
