import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export interface AutoModel {
  _id?: string;
  name: string;
  code: string;
  vehicleType?: string;
  segments?: {
      year: string;
      segment: string;
  }[];
}

export interface AutoEntity {
  _id?: string;
  name: string;
  code: string;
  exCode: string;
  autoModels: AutoModel[];

  isActive: boolean;
  createdBy?: any;
  creationDate?: Date;
  updatedBy?: any;
  lastUpdatedDate?: Date;
}

@Injectable({
  providedIn: 'root'
})
export class AutoService {

  constructor(private http: HttpClient) {}

  createAuto(autoEntity: AutoEntity) {
    return this.http.post(`${environment.url}auto`, autoEntity);
  }

  getAuto(id: string) {
    return this.http.get<AutoEntity>(`${environment.url}auto/${id}`);
  }

  updateAuto(id: string, autoEntity: AutoEntity) {
    return this.http.patch(`${environment.url}auto/${id}`, autoEntity);
  }

  getAllOuto(filters?: any) {
    filters = {};
    return this.http.post<AutoEntity[]>(`${environment.url}auto/all`, filters);
  }

  updateMultipleAutos(request: {data: any[], segments: any[]}) {
    return this.http.post(`${environment.url}auto/updateMultipleAutos`, request);
  }

}
