<div class="profile-card" (click)="$event.stopPropagation()">

    <div class="profile-header">
        <strong>{{this.name}} {{this.surname}}</strong>
        <div>{{this.asistanceFirmaName}}</div>
    </div>

    <div class="profile-body">
        <i class="pi pi-user" style="font-size: 46px"></i>
        <div class="info-list">
            <div *ngFor="let info of userInfo" class="info-line">
                <i [class]="info.icon"></i>
                <span class="p-ml-2">{{ info.value }}</span>
            </div>
        </div>
    </div>

</div>