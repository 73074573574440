import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { SupplierVehicleTypeEnum } from '../../../domain/decorators/supplier-vehicle-type.decorator';
import { ISupplierStaff, SupplierStaffDto } from '../../../domain/supplier-staff';
import { AuthService } from '../../../services/auth/auth.service';
import { AppMessageService } from '../../../services/common/app-message.service';
import { UtilsService } from '../../../services/common/utils.service';
import { SupplierStaffService } from '../../../services/settings/supplier-staff.service';
import { SupplierService } from '../../../services/settings/supplier.service';
import { SupplierStaffListComponent } from '../supplier-staff-list.component';



@Component({
  selector: 'app-supplier-staff-dialog',
  templateUrl: './supplier-staff-dialog.component.html',
  styleUrls: ['./supplier-staff-dialog.component.scss']
})
export class SupplierStaffDialogComponent implements OnInit {
  staffDialog: boolean = false;
  staff: SupplierStaffDto = {};
  supplierStaffList: SupplierStaffDto[] = [];
  vehicleTypeOptions: any;
  suppliersLists: SelectItem[];
  supplierStaffId = '';
  @Input() isSupplier: string;
  @Output() reloadEvent = new EventEmitter<void>();
  @Input() supplierId = '';
  @Output() onNewStaffAdded = new EventEmitter <SupplierStaffDto>();
  @Output() onStaffEdit = new EventEmitter <SupplierStaffDto>();
  @Input() special: boolean=false;
  @Input() selectedSupplier: any;



  constructor(
    private utilsService: UtilsService,
    private auth: AuthService,
    private supplierStaffService: SupplierStaffService,
    private messageService: AppMessageService,
    private supplierService: SupplierService,
    private confirmationService: ConfirmationService,

  ) {
  }

  async ngOnInit(): Promise<void> {
    this.vehicleTypeOptions = this.utilsService.getListFromEnum(SupplierVehicleTypeEnum);
    this.suppliersLists = await this.supplierService.getSupplierItems();
  }


  openStaffDialog(staff?:SupplierStaffDto){
    this.selectedSupplier=this.selectedSupplier ? this.selectedSupplier : this.auth.getUserSupplierId();
    this.staff = staff ? {...staff} : {isActive:true};
    this.staffDialog = true;

  }

  updateOrSave(){
    if(this.staff._id){
      this.editSupplierStaff();
    }else{
      this.addSupplierStaff();
    }
  }



  private addSupplierStaff(): void {
    this.selectedSupplier=this.selectedSupplier ? this.selectedSupplier : this.isSupplier
    this.staff.supplier = this.selectedSupplier;
    if (this.staff.name && this.staff.surname && this.staff.phoneNumber && this.staff.licensePlate && this.staff.supplier) {
      this.supplierStaffService
        .addSupplierStaff(this.staff)
        .subscribe(
          (res: any) => {
            const supplierStaff: ISupplierStaff = res;
            this.onNewStaffAdded.emit(supplierStaff);
            this.messageService.successToast('Başarılı','İşleminiz gerçekleşti.');
            this.staffDialog = false;
            this.reloadEvent.emit();
          },
          (err: any) => {
            let errorMessage = 'Bir hata oluştu'; // varsayılan hata mesajı
          
            // Eğer hata mesajı err nesnesi içerisindeyse, onu al
            if (err && err.error && err.error.message) {
              errorMessage = err.error.message;
            } else if (err && err.message) {
              errorMessage = err.message;
            }
          
            this.messageService.reportError(errorMessage);
          }
        );

    } else {
      let errorMessage = "Çalışan eklenemedi. Lütfen tüm gerekli alanları doldurun:";
      if (!this.staff.name) {
        errorMessage += " Ad yazınız.";
      }
      if (!this.staff.surname) {
        errorMessage += " Soyad yazınız.";
      }
      if (!this.staff.phoneNumber) {
        errorMessage += " Telefon Numarası yazını.";
      }
      if (!this.staff.licensePlate) {
        errorMessage += " Araç Plakasını Yazınız.";
      }
      if (!this.staff.supplier && !this.isSupplier) {
        errorMessage += " Tedarikçi Seçiniz.";
      }
      this.messageService.reportError(errorMessage);
    }
  }


   private editSupplierStaff(): void {
    if (this.staff && this.staff.name && this.staff.surname && this.selectedSupplier) {
      this.staff.supplier = this.selectedSupplier;
      this.supplierStaffService
        .editSupplierStaff(this.staff._id, this.staff)
        .subscribe(res => {
          const supplierStaff: ISupplierStaff = res;
          this.onStaffEdit.emit(supplierStaff);
          this.staff = res;
          this.messageService.successToast('Güncellendi','İşleminiz gerçekleşti.');
          this.staffDialog = false;
          this.reloadEvent.emit();
        },
        (err: any) => {
          this.messageService.reportError('Telefon numarası ile aktif kayıt mevcuttur.');
        }
      );
    } else {
      this.messageService.reportError('Telefon numarası ile aktif kayıt mevcuttur.');
    }
  }

}
