import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { Credentials } from "./credentials.interface";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { RolesEnum } from "src/app/domain/decorators/roles.decorator";
import { IToken } from "./jwt-token.interface";
import { AppSettingsService } from "../settings/app-settings.service";

export interface IRefreshTokenResult {
    access_token: string;
    refresh_token: string;
}

@Injectable({
    providedIn: "root",
})
export class AuthService {
    private authNavStatusSource = new BehaviorSubject<boolean>(false);
    public authNavStatus$ = this.authNavStatusSource.asObservable();
    private loggedIn = false;
    private decodedToken: any = null;

    constructor(
        private http: HttpClient,
        private router: Router,
        private appSettings: AppSettingsService
    ) {
        this.loggedIn = !!localStorage.getItem("accessToken");
        this.authNavStatusSource.next(this.loggedIn);
    }

    login(credentials: Credentials) {
        let url = `${environment.url}auth/sign-in`;
        let data: any = credentials;
        if (this.appSettings.loginStrategy === "ldap") {
            url = `${environment.url}auth/ldap`;

            data = {
                username: credentials.email,
                password: credentials.password,
                captchaToken: credentials.captchaToken,
                otpCode: credentials.otpCode,
            };
        }

        return this.http.post<IToken>(url, data).pipe(
            map((res) => {
                if (res.accessToken) {
                    localStorage.setItem("accessToken", res.accessToken);
                    localStorage.setItem("refreshToken", res.refreshToken);
                    this.decodedToken = null;
                    this.loggedIn = true;
                    this.authNavStatusSource.next(true);
                    return true;
                }

                return false;
            }),
            catchError(this.handleError)
        );
    }

    getOtpCode(credentials: Credentials) {
        let url = `${environment.url}auth/get-otp-code`;
        let data: any = credentials;
        if (this.appSettings.loginStrategy === "ldap") {
            url = `${environment.url}auth/ldap/get-otp-code`;

            data = {
                username: credentials.email,
                password: credentials.password,
            };
        }
        return this.http.post<any>(url, data).pipe(
            map((res: any) => {
                if (res) {
                    return { success: true, phone: res.phone };
                }
                return { success: false };
            }),
            catchError(this.handleError)
        );
    }

    doRefresh(): Observable<any> {
        const refreshToken = localStorage.getItem("refreshToken");
        const data = { refreshToken };
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");

        return this.http
            .post(`${environment.url}auth/refresh-token`, data, { headers })
            .pipe(
                map((res: IRefreshTokenResult) => {
                    if (res.access_token) {
                        localStorage.setItem("accessToken", res.access_token);
                        localStorage.setItem("refreshToken", res.refresh_token);
                        this.decodedToken = null;
                        this.loggedIn = true;
                        this.authNavStatusSource.next(true);
                        return res;
                    }
                })
            );
    }

    refreshToken(refreshToken: string): Observable<any> {
        const data = { refreshToken };
        const headers = new HttpHeaders();
        headers.append("Content-Type", "application/json");
        return this.http.post(`${environment.url}auth/refresh-token`, data, {
            headers,
        });
    }

    logout() {
        this.http.delete(`${environment.url}auth/logout`).subscribe(
            (s) => {/*console.log(s)*/},
            (e) => console.log(e),
            () => {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
                this.decodedToken = null;
                this.loggedIn = false;
                this.authNavStatusSource.next(false);

                this.router.navigate(["/login"]);
            }
        );
    }

    resetPassword(oldPassword: string, newPassword: string, otpToken: string) {
        const data = { oldPassword, newPassword, otpToken };

        return this.http.post<{ result: "success" | "token" }>(
            `${environment.url}auth/reset-password`,
            data
        );
    }

    verifyRecaptcha(response: string) {
        return this.http.post<{ success: boolean; errors: string }>(
            `${environment.url}auth/verify-recaptcha`,
            { response }
        );
    }

    forgotPassword(email: string, captchaToken: string, otpToken: string) {
        const data = { email, captchaToken, otpToken };

        return this.http.post<{ result: string }>(
            `${environment.url}auth/forgot-password`,
            data
        );
    }

    //Pasif olan bir kullanıcı aktif edildiğinde otp oluşturularak bu kullanıcı emailine otp maili gönderilir.
    userActiveOtp(userId: string) {
        const data = { userId };

        return this.http.post<{ result: string }>(
            `${environment.url}auth/user-activate`,
            data
        );
    }

    getToken() {
        return localStorage.getItem("accessToken");
    }

    getRefreshToken() {
        return localStorage.getItem("refreshToken");
    }

    isLoggedIn() {
        const token = this.getToken();
        const refreshToken = this.getRefreshToken();

        if (token === undefined || token === null) {
            return false;
        }

        const jwtHelper = new JwtHelperService();
        if (jwtHelper.isTokenExpired(token)) {
            return this.refreshToken(refreshToken).pipe(
                map((res: IRefreshTokenResult) => {
                    if (res.access_token) {
                        localStorage.setItem("accessToken", res.access_token);
                        localStorage.setItem("refreshToken", res.refresh_token);
                        this.decodedToken = null;
                        this.loggedIn = true;
                        this.authNavStatusSource.next(true);
                        return res;
                    }
                })
            );
        }
        return !jwtHelper.isTokenExpired(token);
    }

    getUserClaim(claim: string): string {
        const token = this.getToken();

        if (token === undefined || token === null) {
            return "";
        }

        const jwtHelper = new JwtHelperService();

        if (this.decodedToken === null) {
            this.decodedToken = jwtHelper.decodeToken(token);
        }

        return this.decodedToken[claim];
    }

    getUserId() {
        return this.getUserClaim("id");
    }

    getUserEmail() {
        return this.getUserClaim("email");
    }

    getUserName() {
        return this.getUserClaim("name");
    }
    getUserSurname() {
        return this.getUserClaim("surname");
    }

    getUserRole() {
        return this.getUserClaim("role");
    }

    getUserLevel() {
        return this.getUserClaim("level");
    }

    getUserSupplierId() {
        return this.getUserClaim("supplierId"); //Bu idden farklı... Tedarikçi olanların ilişkili olduğu tedarikçinin idsi.
    }


    getUserFirmId() {
        return this.getUserClaim("asistanceFirm");
    }
    getUserOctopus() {
        return this.getUserClaim("octopus");
    }
    // getUserRoles() {}    jwt ' ye roles alanı set edilebiilir!

    isUser(role: RolesEnum): boolean {
        return this.getUserRole() === role.toString();
    }

    protected handleError(error: any) {
        if (error.headers) {
            const applicationError = error.headers.get("Application-Error");

            // either applicationError in header or model error in body
            if (applicationError) {
                return throwError(applicationError);
            }
        }

        if (error.status === 401) {
            return throwError(error.error.message);
        }

        if (error.status === 400) {
            let errorMessage = "Giriş Bilgileri Hatalı Girildi";
            if (error.error.message) {
                errorMessage = error.error.message;
            }
            return throwError(errorMessage);
        }

        if (error.error?.message) {
            return throwError(error.error.message);
        }

        if (error.message) {
            return throwError(error.message);
        }

        return throwError(error);
    }

    getUserRoles(id: string) {
        return this.http.get<any>(`${environment.url}auth/getUserRoles/${id}`);
    }
}
