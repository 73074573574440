import { Component, OnInit } from '@angular/core';
import { CampaignDto } from './dto/campaign';
import { CampaignsService } from './services/campaigns.service';
import { AppMessageService } from '../../services/common/app-message.service';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit {
  campaigns: any[] = [];
  dialog: boolean = false;
  variables: any[] = [];
  data: CampaignDto = {};  // Initialize an empty object

  constructor(
    private campaignsService: CampaignsService,
    private messageService: AppMessageService
  ) { }


  ngOnInit() {
    this.loadData();
    this.variables = [
      { name: 'Kampanya Kodu', code: '[kampanyaKodu]' },
      { name: 'Kampanya Tutarı', code: '[kampanyaTutari]' },
      { name: 'Sigoratalı Adı Soyadı', code: '[adSoyad]' },
      { name: 'Hizmet Numarası', code: '[hizmetNo]' },
      { name: 'Plaka Numarası', code: '[plaka]' },
      { name: 'Hizmet Adı', code: '[hizmetTipiAdi]' }
    ];
  }

  loadData() {
    this.campaignsService.getAll().subscribe((data: any[]) => {
      this.campaigns = data;
    });
  }

  openDialog(data?: CampaignDto) {
    this.data = {}
    if (data?._id) {
      this.data = data
    }
    this.dialog = true;
  }

  saveOrUpdate() {
    if (this.data?._id) {
      // Kampanya mevcutsa güncelleme işlemi yapılır
      this.campaignsService.update(this.data._id, this.data).subscribe(response => {
        this.messageService.successToast('Başarılı', 'Kampanya başarılı bir şekilde güncellenmiştir.');
        this.dialog = false;
        this.loadData();
      }, error => {
        this.messageService.errorToast('Başarısız', 'Kampanya güncellenemedi.');
      });
    } else {
      if (!this.data.campaignName) {
        this.messageService.reportError('Kampanya Adı zorunludur.')

      } else if (!this.data.nocTemplateId) {
        this.messageService.reportError('NOC Template ID zorunludur')

      } else if (!this.data.campaignSmsText) {
        this.messageService.reportError('Kampanya SMS Metni zorunludur.')

      } else {
        // Yeni kampanya oluşturma işlemi yapılır
        this.campaignsService.create(this.data).subscribe(response => {
          this.messageService.successToast('Başarılı', 'Kampanya başarılı bir şekilde tanımlanmıştır.');
          this.dialog = false;
          this.loadData();
        }, error => {
          this.messageService.errorToast('Başarısız', 'Kampanya tanımlanamadı.');
        });
      }
    }
  }

}