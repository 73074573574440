export const serviceMap = {
    complaintreason: {
        title: "Şikayet Sebepleri",
        prefix: "table",
        options: {
            grid: true,
            size: 'small',
            pageReport: true
        },
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,

            },
            {
                type: "string",
                field: "name",
                header: "Sebep",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,

                header: "Tarih ",
            },

            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            },
        ]
    },
    occurrencetype: {
        title: "Olay Tipleri",
        prefix: "table",
        options: {
            grid: false,
            size: 'small'
        },
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Olay Tipi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    occurrencesubtype: {
        title: "Olay Alt Tipleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Olay Alt Tipi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "multiSelect",
                field: "occurrencetype",
                header: "Bağlı Olduğu Olay Tipi",
                options: {
                    action: "multiSelect",
                    multiSelect: {
                        from: {
                            entity: "occurrencetype",
                            options: {
                                label: "name",
                                value: "_id",
                                filters: [
                                    {
                                        field: "isActive",
                                        value: true
                                    }
                                ]
                            }
                        },
                        field: "name"
                    },
                },
                required: true,
                filter: true,

            },

            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    complaintsource: {
        prefix: "table",
        title: "Şikayet Kaynakları",
        options: {
            grid: false,
            size: 'small'
        },
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Kaynak",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,

                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    customertype: {
        title: "Müşteri Tipleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Müşteri Tipi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                header: "Tarih ",
                unEditable:true,

            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    querytype: {
        title: "Sorgu Tipleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "name",
                header: "Sorgu Tipi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    filetype: {
        title: "Dosya Tipleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Hizmet Tipi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "string",
                field: "code",
                header: "Hizmet Kodu",
                required: true,
                filter: true,
                sort: true,
            },

            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                header: "Tarih ",
                unEditable:true,

            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    filestatus: {
        title: "Dosya Durumları",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Dosya Durumu",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },

            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    payertype: {
        title: "Yüklenici Tipleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Yüklenici Tipi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    currencytype: {
        title: "Ödeme Birimi Tipleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Ödeme Birimi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },

            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    
    servicecategory: {
        title: "Hizmet Kategorileri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Kategori",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },

            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                header: "Tarih ",
                unEditable:true,

            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    locationtypes: {
        title: "Lokasyon Tipleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Lokasyon Tipi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                header: "Tarih ",
                unEditable:true,

            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    servicestatus: {
        title: "Hizmet Durumları",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "name",
                header: "Durum",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    supplieroperationstatus: {
        title: "Tedarikçi Operasyon Durumları",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "name",
                header: "Operasyon Durumu",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    occurrencefiles: {
        title: "Dosya Listesi",
        options: {
            addButton: false
        },
        colunms: [
            {
                type: "string",
                field: "_id",
                header: "ID",
                ignore: true
            },
            {
                type: "link",
                field: "fileCode",
                header: "Dosya Numarası",
                filter: true,
                options: {
                    action: "link",
                    link: {
                        url: "#/operator/file",
                        fieldToAdd: "_id"
                    }
                }
            },
            {
                type: "translate",
                field: "occurrenceFileStatus",
                header: "Dosya Durumu",
                options: {
                    action: "translate",
                    translate: "occurrenceFileStatus"
                }

            },
            {
                type: "array",
                field: "services",
                header: "Hizmet Sayısı",

            },
            {
                type: "object",
                field: "createdBy",
                header: "Oluşturan Kişi",
                options: {
                    action: "object",
                    object: ['name']
                }
            },
            {
                type: "date",
                field: "creationDate",
                header: "Oluşturulma Tarihi",
                options: {
                    action: "date",
                    date: {
                        hours: true
                    }
                }

            },
            {
                type: "object",
                field: "subscriberPolicy",
                header: "Müşteri",
                options: {
                    action: "object",
                    object: ['customer', 'name']
                }
            },
            {
                type: "object",
                field: "subscriberPolicy",
                header: "Poliçe Numarası",
                options: {
                    action: "object",
                    object: ['policyNo']
                }
            },
            {
                type: "translate",
                field: "serviceType",
                header: "Dosya Gurubu",
                options: {
                    action: "translate",
                    translate: "serviceType"
                }
            },
            {
                type: "object",
                field: "occurrenceType",
                header: "Olay Tipi",
                options: {
                    action: "object",
                    object: ["name"]
                }
            },
            {
                type: "object",
                field: "occurrenceSubType",
                header: "Olay Alt Tipi",
                options: {
                    action: "object",
                    object: ["name"]
                }
            },
            {
                type: "object",
                field: "subscriberPolicy",
                header: "Plaka",
                options: {
                    action: "object",
                    object: ['licensePlate']
                }
            },
        ]
    },
    taskcategory: {
        title: "Görev Kategorisi",
        colunms: [

            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "category",
                header: "Kategori",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "string",
                field: "description",
                header: "Açıklama",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },

            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,

                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            },
        ]
    },
    tasksubcategory: {
        title: "Görev Alt Kategori",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "subcategory",
                header: "Alt Kategori",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "multiSelect",
                field: "category",
                header: "Kategori",
                options: {
                    action: "multiSelect",
                    multiSelect: {
                        from: {
                            entity: "taskcategory",
                            options: {
                                label: "category",
                                value: "_id",
                                filters: [
                                    {
                                        field: "isActive",
                                        value: true
                                    }
                                ]
                            }
                        },
                        field: "category"
                    },
                },
                required: true,
                filter: true,

            },
            {
                type: "string",
                field: "description",
                header: "Açıklama",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },

            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,

                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            },

        ]
    },
    countries: {
        title: "Ülke Listesi",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Ülke",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            }
            ,
            {
                type: "string",
                field: "code",
                header: "Ülke Kodu",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },

            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                header: "Tarih ",
                unEditable:true,

            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            },
        ]
    },

    cities: {
        title: "İl Listesi",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },

            {
                type: "string",
                field: "name",
                header: "Şehir",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            }
            ,
            // {
            //     type: "string",
            //     field: "mipValueName",
            //     header: "MipValueName",
            //     required: true,
            //     filter: true,
            //     sort: true,
            // },
            // {
            //     type: "string",
            //     field: "mipValueId",
            //     header: "MipValueID",
            //     required: true,
            //     filter: true,
            //     sort: true,
            // },
            {
                type: "string",
                field: "code",
                header: "Şehir Kodu",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "multiSelect",
                field: "country",
                header: "Ülke",
                options: {
                    action: "multiSelect",
                    multiSelect: {
                        from: {
                            entity: "countries",
                            options: {
                                label: "name",
                                value: "_id",
                                filters: [
                                    {
                                        field: "isActive",
                                        value: true
                                    }
                                ]
                            }
                        },
                        field: "name"
                    },
                },
                required: true,
                filter: true,

            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                header: "Tarih ",
                unEditable:true,

            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            },

        ]
    },
    towns: {
        title: "İlçe Listesi",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "İlçe Adı",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "multiSelect",
                field: "city",
                header: "Bağlı Olduğu İl",
                options: {
                    action: "multiSelect",
                    multiSelect: {
                        from: {
                            entity: "cities",
                            options: {
                                label: "name",
                                value: "_id",
                                filters: [
                                    {
                                        field: "isActive",
                                        value: true
                                    }
                                ]
                            }
                        },
                        field: "name"
                    },
                },
                required: true,
                filter: true,

            },
            // {
            //     type: "string",
            //     field: "mipValueName",
            //     header: "MipValueName",
            //     required: true,
            //     filter: true,
            //     sort: true,
            // },
            // {
            //     type: "string",
            //     field: "mipValueId",
            //     header: "MipValueID",
            //     required: true,
            //     filter: true,
            //     sort: true,
            // },
            {
                type: "number",
                field: "code",
                header: "İlçe Kodu",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },

            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            },
        ]
    },

    filecancelreason: {
        title: "Dosya İptal Sebepleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "İptal Sebebi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "boolean",
                field: "isDescriptionRequired",
                header: "Açıklama Gerekliliği"
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    filenotetypes: {
        title: "Dosya Notu Tipi",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                options: { action: "openable" },
                required: true,
            },
            {
                type: "string",
                field: "type",
                header: "Tipi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "string",
                field: "subject",
                header: "Konusu",
                required: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },

    fileclosereason: {
        title: "Dosya Kapatma Nedenleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Kapatma Sebebi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "boolean",
                field: "isDescriptionRequired",
                header: "Açıklama Gerekliliği"
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },

    fileopenreason: {
        title: "Dosya Açma Nedenleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Açma Sebebi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "boolean",
                field: "isDescriptionRequired",
                header: "Açıklama Gerekliliği"
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },

    servicecancelreason: {
        title: "Hizmet İptal Sebepleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "İptal Sebebi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },

    customernotes: {
        title: "Özel Müşteri Notları",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "value",
                header: "Değer",
                required: true,
                options: { action: "openable" },
            },
            {
                type: "string",
                field: "description",
                header: "Açıklama",
                required: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            },
            {
                type: "string",
                field: "user",
                header: "Kullanıcı",
                required: true,
                options: { action: "openable" },
            }
        ]
    },

    changesupplierreason: {
        title: "Tedarikçi Bölge Değiştirme Sebepleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Değiştirme Sebebi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },

    servicerejectionreasons: {
        title: "Hizmet Reddi Nedenleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Hizmet Reddi Nedeni",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    transactionchannel: {
        title: "İşlem Kanalı",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "İşlem Kanalı Adı",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    changeserviceinformationreason: {
        title: "Hizmet Bilgileri Değiştirme Nedenleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Hizmet Bilgisi Değiştirme Nedeni",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    dayextentionreason: {
        title: "Gün Uzatımı Nedenleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Gün Uzatımı Nedeni",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    slacategories: {
        title: "SLA Kategorileri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "SLA Kategori Adı",
                options: { action: "openable" },
                required: true,
                filter: true,
            },
            {
                type: "number",
                field: "minValue",
                header: "Başlangıç Değeri",
                required: true,
                sort: true,
                filter: true,
            },
            {
                type: "number",
                field: "maxValue",
                header: "Bitiş Değeri",
                required: true,
                sort: true,
                filter: true,
            },
            {
                type: "string",
                field: "description",
                header: "Açıklama",
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    segmenttypes: {
        title: "Segment Tipi",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Segment Tipi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "number",
                field: "number",
                header: "Sıra Numarası",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    polltype: {
        title: "Anket Tipleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Anket Tipi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    rolegroup: {
        title: "Rol Gurubu Tanımları",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Gurup Adı",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "string",
                field: "description",
                header: "Açıklama",
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    usergroup: {
        title: "Kullanıcı Grubu Tanımları",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Grup Adı",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "string",
                field: "description",
                header: "Açıklama",
            },

            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    notificationchannel: {
        title: "Bildirim Kanalı",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Bildirim Kanalı Adı",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            // {
            //     type: "string",
            //     field: "mipValueName",
            //     header: "MipValueName",
            //     required: true,
            //     filter: true,
            //     sort: true,
            // },
            // {
            //     type: "string",
            //     field: "mipValueId",
            //     header: "MipValueID",
            //     required: true,
            //     filter: true,
            //     sort: true,
            // },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    permissiongroup: {
        title: "Yetki Grupları",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Yetki Grubu",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    changedeliveryreason: {
        title: "Teslim Adresi Değişikliği Nedenleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "İşlem Nedeni",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },

    fueltypes: {
        title: "Yakıt Türleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                filter: true,
                sort: true,
            },
            {
                type: "string",
                field: "name",
                header: "Yakıt Türü",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            // {
            //     type: "string",
            //     field: "asosName",
            //     header: "ASOS Karşılığı ",
            //     required: true,
            //     options: { action: "openable" },
            //     filter: true,
            //     sort: true,
            // },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    vehiclegears: {
        title: "Vites Tipleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Vites Tipi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "number",
                field: "number",
                header: "Sıra Numarası",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },

    towvehicletype: {
        title: "Çekici Araç Tipleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Çekici Tipi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },

    vehicletype: {
        title: "Araç Tipleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Araç Tipi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            // {
            //     type: "array",
            //     field: "asosName",
            //     header: "Asos Karşılığı",
            //     filter: true,
            //     sort: true,
            // },
            // {
            //     type: "array",
            //     field: "rhNames",
            //     header: "RH Karşılığı",
            //     filter: true,
            // },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    vehiclefeatures: {
        title: "Araç Özellikleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Araç Özelliği",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",

            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                header: "Tarih ",
                unEditable:true
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    vehiclecolors: {
        title: "Araç Renkleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Renkler",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    paymentheader: {
        title: "Ödeme Başlık Tipleri",
        prefix: "payment-service",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Ödeme Başlık Tipi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "string",
                field: "description",
                header: "Açıklama",
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            },
        ]
    },
    paymenttype: {
        title: "Ödeme Tipleri",
        prefix: "payment-service",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Ödeme Tipi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "multiSelect",
                field: "service",
                header: "Hizmet Tipleri",
                options: {
                    action: "multiSelect",
                    multiSelect: {
                        from: {
                            entity: "services",
                            options: {
                                label: "name",
                                value: "_id",
                                filters: [
                                    {
                                        field: "isActive",
                                        value: true
                                    }
                                ]
                            }
                        },
                        field: "name"
                    },
                },
                required: true,
                //filter: true,
            },
            {
                type: "string",
                field: "description",
                header: "Açıklama",
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            },
        ]
    },
    paymentdetailtype: {
        title: "Ödeme Detay Tipleri",
        prefix: "payment-service",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Ödeme Detay Tipi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "string",
                field: "description",
                header: "Açıklama",
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            },
        ]
    },
    paymentreason : {
        title: "Ödeme Nedenleri",
        prefix: "payment-service",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "description",
                header: "Ödeme Nedeni",
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            },
        ]
    },
    valuedate : {
        title: "Valör Gün ",
        prefix: "payment-service",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "multiSelect",
                field: "service",
                header: "Hizmet Tipi",

                options: {
                    action: "multiSelect ",
                    multiSelect: {
                        from: {
                            entity: "services",
                            options: {
                                label: "name",
                                value: "_id",
                                filters: [
                                    {
                                        field: "isActive",
                                        value: true
                                    }
                                ]
                            }
                        },
                        field: "name"
                    },
                },
                required: true,
                filter: true,
            },
            {
                type: "multiSelect",
                field: "paymentHeader",
                header: "Ödeme Başlık Tipi",
                options: {
                    action: "multiSelect",
                    multiSelect: {
                        from: {
                            entity: "paymentHeader",
                            options: {
                                label: "name",
                                value: "_id",
                                filters: [
                                    {
                                        field: "isActive",
                                        value: true
                                    }
                                ]
                            }
                        },
                        field: "name"
                    },
                },
                required: true,
                filter: true,
            },

            {
                type: "number",
                field: "day",
                header: "Valör Gün",
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",

            },
            // {
            //     type: "button",
            //     options: { action: "openable" },
            //     field: "<i class='fas fa-edit'></i>",
            //     header: "Düzenle",
            //     style: "background-color: blue; color: white;"
            // },
        ]
    },
    reasonrefund: {
        title: "Geri Ödeme Sebepleri",
        prefix: "payment-service",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Geri Ödeme Sebebi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "string",
                field: "description",
                header: "Açıklama",
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            },
        ]
    },
    currency: {
        title: "Para Birimleri",
        prefix: "payment-service",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Para Birimi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "string",
                field: "symbol",
                header: "Sembol",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            },
        ]
    },
    expensetype: {
        title: "Masraf Tipleri",
        prefix: "payment-service",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Masraf Tipi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                type: "string",
                field: "description",
                header: "Açıklama",
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            },
        ]
    },
    objectreason: {
        title: "Ödeme İtiraz Nedenleri",
        prefix: "payment-service",
        colunms: [
            {
                type: "string",
                field: "name",
                header: "İtiraz Nedeni",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            },
        ]
    },
    servicecreatreason: {
        title: "Hizmet Oluşturma Nedenleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Oluşturma Sebebi",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                header: "Tarih ",
                unEditable:true,
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    statusgroupsummary: {
        title: "Durum Grup Özetleri",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: true,
            },
            {
                type: "string",
                field: "name",
                header: "Durum Grup Özeti",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },

            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },
    pricecancelreason: {
        title: "Fiyat İptal Nedenleri",
        prefix: "table",
        colunms: [
            {
                type: "string",
                field: "exCode",
                header: "Referans Kodu",
                required: false,
            },
            {
                type: "string",
                field: "name",
                header: "İptal Nedeni",
                required: true,
                options: { action: "openable" },
                filter: true,
                sort: true,
            },
            {
                field: "createdBy",
                type: "object",
                options: {
                    action: "object",
                    object: ["userName"]
                },
                header: "Oluşturan ",
            },
            {
                field: "creationDate",
                type: "date",
                options:{
                    acion:"date",
                    date:{
                        hours:true
                    }
                },
                unEditable:true,
                header: "Tarih ",
            },
            {
                type: "boolean",
                field: "isActive",
                header: "Aktifliği",
            }
        ]
    },

};
