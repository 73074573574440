import { DatePipe, Location } from "@angular/common";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { LazyLoadEvent, SelectItem } from "primeng/api";
import { Calendar } from "primeng/calendar";
import { DashboardData } from "src/app/domain/dashboard-data";
import { RolesEnum } from "src/app/domain/decorators/roles.decorator";
import { AuthService } from "src/app/services/auth/auth.service";
import { DashboardService } from "src/app/services/dashboard/dashboard.service";
import {
    DashboadrDateType,
    ISubscriberServiceFilterDto,
} from "../../domain/subscriber-service";
import { IDashboardInfoV2 } from "../../services/dashboard/dashboard.interface";
import { CityService } from "../../services/settings/city.service";
import { CustomerService } from "../../services/settings/customer.service";
import { ServiceService } from "../../services/settings/service.service";
import { SupplierService } from "src/app/services/settings/supplier.service";
import { AnnouncementShowComponent } from "../operator/announcement/components/announcement-show/announcement-show.component";
import { AnnouncementService } from "../operator/announcement/services/announcement.service";
import { DialogService } from "primeng/dynamicdialog";
import { FileListNotesComponent } from "../operator/file-list-v2/file-list-notes/file-list-notes.component";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
    providers: [DatePipe],
})
export class HomeComponent implements OnInit, OnDestroy {
    @ViewChild("showAnnouncement")
    showAnnouncementComponent: AnnouncementShowComponent;
    @ViewChild("calendar") calendar: Calendar;
    private updateSubscription;

    data: DashboardData = {} as any;
    mapInfo: any = { name: "TÜRKİYE", count: "0", city: "0" };

    announcements: any[] = [];
    readButton = true;

    filter: ISubscriberServiceFilterDto = {
        limit: -1,
        offset: 0,
        dateType: "daily",
        startDate: new Date(),
        endDate: new Date(),
        orderBy: "asc",
        customer: "",
        cities: [],
        serviceTypes: [],
        supplierList: [],
        // supplierType:[],
        supplier: "",
        assigned: undefined,
    };

    customerList: SelectItem[] = [];
    cityList: SelectItem[] = [];
    city: string;
    serviceTypeList: SelectItem[] = [];

    dates: Date[] = [];

    datesText = "";
    datesSelectionText = "";

    selectedHizmet = "ikame";

    dashboardInfo: IDashboardInfoV2 = {
        files: [],
        services: [],
    };

    allowMap = false;
    supplierList: SelectItem[] = [];
    assigned;

    constructor(
        private router: Router,
        private auth: AuthService,
        private dashboardService: DashboardService,
        private customerService: CustomerService,
        private cityService: CityService,
        private serviceService: ServiceService,
        private datePipe: DatePipe,
        private supplierService: SupplierService
    ) {}

    ngOnDestroy(): void {
        clearInterval(this.updateSubscription);
        this.filter.assigned = null;
        if (this.filter.assigned !== null) {
            this.dashboardService.setSupplierType(null);
            this.filter.assigned = null;
        }
    }

    async ngOnInit() {
        this.dashboardService.setSupplierType(null);
        this.filter.assigned = null;
        if (this.filter.assigned !== null) {
            this.filter.assigned = null;
            this.dashboardService.setSupplierType(null);
        }
        const role = this.auth.getUserRole();
        this.supplierList = await this.supplierService.getSupplierItems();
        this.serviceTypeList = await this.serviceService.getServiceItems();

        /*
        if (role === RolesEnum.supplier) {
            this.router.navigate(["/supplier-operations"]);
            return;
        }

        if (role === RolesEnum.user) {
            this.router.navigate(["operator/file-listt"]);
            return;
        }

        if (role === RolesEnum.callCenter) {
            this.router.navigate(["operator/file-list"]);
            return;
        }*/

        const userRole = this.auth.getUserRole();
        this.allowMap =
            [
                RolesEnum.admin.toString(),
                RolesEnum.superAdmin.toString(),
            ].indexOf(userRole) > -1;

        await this.getCitiesAndCustomers();

        const savedFilter = this.dashboardService.loadFilter();

        if (savedFilter) {
            this.filter = savedFilter;
            if (this.filter.cities.length > 0) {
                this.city = this.filter.cities[0];
            }
            this.dashboardService.setFilter(this.filter);
        } else {
            this.dateFilterChanged("daily");
        }

        // Sayfa yenilendiğinde assigned değerini kontrol et
        if (this.filter.assigned !== null) {
            this.assigned = this.filter.assigned;
        }
        // 2 dakikada bir bilgileri güncelle
        this.updateSubscription = setInterval(
            () => this.getDashboardInfos(),
            120000
        );
    }
    async getCitiesAndCustomers() {
        const item: SelectItem[] = [{ value: "", label: "[Tüm Müşteriler]" }];
        const customers = await this.customerService.getCustomerItems();
        this.customerList = item.concat(customers);

        const cityData = await this.cityService.getAllCities().toPromise();
        if (cityData && cityData.rows.length > 0) {
            const cities = cityData.rows.map((m) => ({
                value: m.code,
                label: m.name,
            }));
            this.cityList = (
                [{ value: "", label: "[Tüm İller]" }] as SelectItem[]
            ).concat(cities);

            this.dashboardService.setCities(cityData.rows);
        }

        const services = await this.serviceService.getServiceItems();
        this.dashboardService.setServices(services);
    }

    resetTable() {
        if (this.filter.assigned !== null) {
            this.filter.assigned = null;
            this.dashboardService.setSupplierType(null);
        }
        const selectedSupplierIds = this.filter.supplierList;
        const selectedSuppliers = this.supplierList.filter((supplier) =>
            selectedSupplierIds.includes(supplier.value)
        );
        this.getDashboardInfos();
    }
    assignedChange() {
        this.filter.assigned = this.assigned;
        this.getDashboardInfos();
    }
    cityChange() {
        if (!this.city) {
            this.filter.cities = [];
        } else {
            this.filter.cities = [this.city];
        }

        this.getDashboardInfos();
    }

    dateFilterChanged(dtype: DashboadrDateType) {
        this.datesText = "";
        this.datesSelectionText = "";
        this.filter.dateType = dtype;

        if (this.filter.dateType === "selection") {
            this.dates = [];
            this.calendar.showOverlay();
            this.calendar.cd.detectChanges();
            return;
        }

        this.filter.endDate = new Date();
        this.filter.endDate.setHours(23, 59, 59, 999);

        switch (this.filter.dateType) {
            case "daily":
                this.filter.startDate = new Date();
                this.filter.startDate.setHours(0, 0, 0, 0);
                break;

            case "weekly":
                this.filter.startDate = this.getMonday(new Date());
                break;

            case "monthly":
                this.filter.startDate = this.getBeginingOfMonth();
                break;

            case "yearly":
                this.filter.startDate = this.getBeginingOfYear();
                break;
        }

        this.getDashboardInfos();
    }

    onDatesSelected(e: any) {
        if (this.dates.length === 3) {
            this.dates = [this.dates[2]];
        } else if (this.dates && this.dates[0] && this.dates[1]) {
            this.datesSelectionText =
                this.datePipe.transform(this.dates[0], "dd.MM.yyyy") +
                " - " +
                this.datePipe.transform(this.dates[1], "dd.MM.yyyy");
        } else {
            this.datesSelectionText = "Aralık seçilmedi";
        }
    }

    onDatesConfirm() {
        if (this.dates.length !== 2) {
            return;
        }

        this.filter.startDate = this.dates[0];
        this.filter.startDate.setHours(0, 0, 0, 0);

        this.filter.endDate = this.dates[1];
        this.filter.endDate.setHours(23, 59, 59, 999);

        this.dates = [];

        this.datesText =
            this.datePipe.transform(this.filter.startDate, "dd.MM.yyyy") +
            " - " +
            this.datePipe.transform(this.filter.endDate, "dd.MM.yyyy");

        this.calendar.hideOverlay();
        this.getDashboardInfos();
    }

    onCancelCalendar() {
        this.calendar.hideOverlay();
    }

    async getDashboardInfos() {
        if (
            this.filter.supplierList.length > 0 &&
            this.filter.assigned === false
        ) {
            this.filter.supplierList = [];
            console.log(
                "filter.supplierList silindi. Yeni uzunluk: " +
                    this.filter.supplierList.length
            );
            const value = this.dashboardService.setSupplierType(false);
            console.log("assigned value:" + value);
        }
        if (this.filter.assigned !== null) {
            // this.filter.assigned = null;
            this.dashboardService.setSupplierType(null);
        }
        const selectedSuppliers: SelectItem[] = this.filter.supplierList.map(
            (item) => ({
                label: item,
                value: item,
            })
        );

        this.dashboardService.setFilter(this.filter);
        this.dashboardService.setSuppliers(selectedSuppliers);
        this.dashboardService.setSupplierType(this.filter.assigned);
    }

    // functions for date filters
    getBeginingOfMonth(month?: number) {
        const today = new Date();
        if (!month) {
            month = today.getMonth(); // -1 yaparak önceki ayın kayıtlarını alarak test ediyorum. -1 var ise silinecek
        }
        return new Date(Date.UTC(today.getFullYear(), month, 1));
    }

    getBeginingOfYear(year?: number) {
        const today = new Date();
        if (!year) {
            year = today.getFullYear();
        }
        return new Date(Date.UTC(year, 0, 1));
    }

    getEndOfYear(year?: number) {
        const today = new Date();
        if (!year) {
            year = today.getFullYear();
        }
        return new Date(Date.UTC(year, 11, 31));
    }

    getMonday(d) {
        d = new Date(d);
        d.setHours(0, 0, 0);
        let day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }
}
