import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { saveAs } from "file-saver";
import { IFile } from "../../domain/file";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class MediaService {
    constructor(private http: HttpClient) {}

    saveDocument(data: FormData) {
        return this.http.post<any>(
            `${environment.url}mediaService/upload`,
            data
        );
    }

    getFile(piid: string) {
        return this.http
            .get<any>(`${environment.url}mediaService/get-document/${piid}`, {
                responseType: "blob" as "json", // Dosyayı Blob olarak almak için ayar
            })
            .pipe(
                map((blob: Blob) => URL.createObjectURL(blob)) // Blob'u URL'ye çevirip döndürüyoruz
            );
    }

    download(file: IFile) {
        saveAs(file.url, file.filename);
    }

    removeFile(id: string, fileId: string) {
        return this.http.delete<IFile[]>(
            `${environment.url}cm/deleteFile/${id}/${fileId}`
        );
    }
}

export interface IDocumentData {
    fileNo?: string;
    serviceNo?: string;
    status?: string;
    billNo?: string;
    announcementNo?: string;
    sender: MediaSenderEnum;
}

export enum StoragePlaceEnum {
    bill = "bill",
    photo = "photo",
    files = "files",
    announcements = "announcements",
    poFile = "poFile",
}

export enum MediaSenderEnum {
    supplier = "supplier",
    supplierStaff = "supplierStaff",
    callCenter = "callCenter",
    citizen = "citizen",
}
