import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SelectItem } from "primeng/api";
import { Observable } from "rxjs";
import { IListEnvelope } from "src/app/domain/list.interface";
import { IUser } from "src/app/domain/user";
import { environment } from "src/environments/environment";
import { NewUserCreateDto, UserDto } from "../dto/user.dto";
import { UserEntity } from "../models/users-entity.interface";
import { IUserEdit } from "../user-list/interfaces/user-edit.interface";

@Injectable({
    providedIn: "root",
})
export class UserService {
    constructor(private http: HttpClient) {}

    createUser(newUser: NewUserCreateDto): Observable<any> {
        return this.http.post<UserEntity>(`${environment.url}users`, newUser);
    }

    editUser(id:string, user: UserDto) {
        return this.http.put<UserEntity>(`${environment.url}users/${id}`, user);
    }

    getAll(page: number, limit: number, text: string, role: string) {
        const query = `?&page=${page ?? ''}&limit=${limit ?? ''}&text=${text ?? ''}&role=${role ?? ''}`;
        return this.http.get<IListEnvelope<UserEntity>>(`${environment.url}users?${query}`);
    }

    deactivateUser(id): Observable<any> {
        return this.http.post(`${environment.url}users/deactivate/${id}`, {});
    }

    checkUserVerified(id, verified: boolean) {
        const data = {verified: verified}
        return this.http.put<UserEntity>(`${environment.url}users/changeVerified/${id}`, data);
    }

    getUserItems() {
        return this.http.get<SelectItem[]>(`${environment.url}users/getItems`);
    }

    saveRoleForUsers(roleId: string, userList: any[]) {
        return this.http.put<string[]>(`${environment.url}users/saveRoleForUsers/${roleId}`, userList);
    }
    getUsersBySupplierId(supplierId: string): Observable<IUser[]> {
        const params = { supplierId };
        return this.http.get<IUser[]>(`${environment.url}users/supplier/${supplierId}/users`);
    }
    isMyBirthday() {
      return this.http.get<boolean>(`${environment.url}users/isMyBirthday`)
    }

    getUsersByFirmId(firmId: string): Observable<IUser[]> {
        return this.http.get<IUser[]>(`${environment.url}users/userFirm/${firmId}`);
    }

    //  Yeni versiyon kullanıcı kontrolü apileri
    getUserInfoForEditById(id: string): Observable<IUserEdit> {
        return this.http.get<IUserEdit>(`${environment.url}users/getUserInfoForEdit/${id}`);
    }
    
}
